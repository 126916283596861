import { ErrorToast } from '../Toast/ErrorToast';
import { InfoToast } from '../Toast/InfoToast';
import { FileDownloadSuccessToast } from '../Toast/FileDownloadSuccessToast';
import { FileCreationToast } from '../Toast/FileCreationToast';
import { NotificationContainer } from './styled';
import { SuccessToast } from '../Toast/SuccessToast';

export const Notification = (): JSX.Element => (
  <NotificationContainer>
    <ErrorToast />
    <InfoToast />
    <FileDownloadSuccessToast />
    <FileCreationToast />
    <SuccessToast />
  </NotificationContainer>
);
