import { useState, useEffect } from 'react';
import theme from 'theme';
import { ButtonWrapper, Tab, TabPanel, DesktopTabs } from '../styled';
import { TabKeys, EventByTab } from '../constants';
import { useText, useQueryParams } from 'hooks';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { Button, Misc } from 'components/ui';

import { DeleteIcon } from 'assets/icons';

import { List as AssessmentsList } from '../../Assessment';
import { List as DocumentList } from '../../Document';
import { List as EvidenceList } from '../../Evidence';
import { HazardsControlsComments } from '../../HazardsControlsComments';
import { List as JobBlockerList } from '../../JobBlocker';
import { List as PermitList } from '../../Permits/List';
import LogsList from '../../Logs/List';
import { WorkOrderItemList } from '../../WorkOrderItem/List';
import Details from './Details';
import TeamDisplay from './TeamDisplay';
import JobData from 'model/JobData';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsGeneratingZip, selectJobsView } from 'store/selectors';
import { getZipExportId } from 'store/actions/jobs';
import { DownloadSource, DownloadType } from '../../FileExport/constants';

const { colors } = theme;

type QueryParamProps = {
  overviewTab?: string;
  view?: string;
};

type JobOverviewDesktopTabSectionParams = {
  jobId?: string;
  isStreamline: boolean;
  permits_enabled: boolean;
  activeJob: JobData;
  isManager: boolean;
  isBackOffice: boolean;
  setShowDeleteJob: (showDeleteJob: boolean) => void;
};

export const JobOverviewDesktopTabSection = ({
  jobId,
  isStreamline,
  permits_enabled,
  activeJob,
  isManager,
  isBackOffice,
  setShowDeleteJob,
}: JobOverviewDesktopTabSectionParams): JSX.Element => {
  const [activeTabKey, setActiveTabKey] = useState<string>(TabKeys.JOB_DETAILS);

  const { params }: { params: QueryParamProps } = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const getText = useText();
  const dispatch = useDispatch();
  const view = useSelector(selectJobsView);
  const isGeneratingZip = useSelector(selectIsGeneratingZip);

  useEffect(() => {
    const tabKey = params.overviewTab;
    const newTab =
      tabKey && Object.values(TabKeys).includes(tabKey)
        ? tabKey
        : TabKeys.JOB_DETAILS;

    setActiveTabKey(newTab);
  }, [params.overviewTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabChange = (_: any, newTabKey: string) => {
    const event = EventByTab[newTabKey];

    if (event) {
      Analytics.trackEvent(event, {
        jobId,
        [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      });
    }
    navigate(
      location.pathname +
        `?${
          newTabKey !== TabKeys.JOB_DETAILS ? `overviewTab=${newTabKey}&` : ''
        }view=${constants.LEGACY_VIEW}`,
    );
  };

  const startZipCreation = () => {
    const eventProperties = {
      jobId,
      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      download_source: DownloadSource.JOB_OVERVIEW,
      download_type: DownloadType.JOB,
    };
    dispatch(getZipExportId(jobId, eventProperties));
  };

  return (
    <>
      <DesktopTabs
        value={activeTabKey}
        onChange={handleTabChange}
        variant='scrollable'
        TabIndicatorProps={{
          style: { backgroundColor: colors.black, height: '3px' },
        }}
      >
        <Tab label={getText('tabs_job_details')} value={TabKeys.JOB_DETAILS} />
        <Tab
          label={getText('tabs_hazards_and_controls')}
          value={TabKeys.HAZARDS_CONTROLS}
        />
        <Tab
          label={getText('tabs_risk_assessments')}
          value={TabKeys.ASSESSMENTS}
        />
        <Tab label={getText('tabs_evidence')} value={TabKeys.EVIDENCE} />
        <Tab
          label={getText('tabs_job_blockers')}
          disabled={isStreamline}
          value={TabKeys.JOB_BLOCKERS}
        />
        <Tab
          label={getText('tabs_documents')}
          disabled={isStreamline}
          value={TabKeys.DOCUMENTS}
        />
        {!isStreamline && (
          <Tab
            label={getText('tabs_work_completed')}
            value={TabKeys.WORK_COMPLETED}
          />
        )}
        {!isStreamline && (
          <Tab label={getText('tabs_logs')} value={TabKeys.LOGS} />
        )}
        {permits_enabled && (
          <Tab label={getText('tabs_permits')} value={TabKeys.PERMITS} />
        )}
      </DesktopTabs>
      {activeTabKey === TabKeys.JOB_DETAILS && (
        <TabPanel>
          <Details activeJob={activeJob} />
          {(activeJob.is_assigned || isManager || isBackOffice) && (
            <ButtonWrapper>
              <Button.SecondaryLink
                event={constants.EVENT_JOB_UPDATE_BUTTON}
                eventProperties={{
                  jobId,
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }}
                text={getText('tabs_edit_job_details')}
                to={`/jobs/${jobId}/edit-details`}
                width='50%'
              />
              {!isStreamline && (
                <Button.Secondary
                  event={constants.EVENT_JOB_REQUEST_FILES}
                  eventProperties={{
                    jobId,
                    [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                  }}
                  text={getText('tabs_request_files')}
                  onClick={startZipCreation}
                  disabled={isGeneratingZip}
                  width='50%'
                  extraStyles={{ marginLeft: '32px' }}
                />
              )}
            </ButtonWrapper>
          )}
          <Misc.Separator height='8px' style={{ margin: '25px 0' }} />
          <TeamDisplay members={activeJob.assignees} />
          {(activeJob.is_assigned || isManager || isBackOffice) && (
            <ButtonWrapper>
              <Button.SecondaryLink
                event={constants.EVENT_EDIT_JOB_TEAM_MEMBERS_BUTTON}
                eventProperties={{
                  jobId,
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }}
                text={getText('tabs_edit_team_members')}
                to={`/jobs/${jobId}/edit-team`}
                width='50%'
              />

              <Button.Secondary
                event={constants.EVENT_DELETE_JOB_BUTTON}
                eventProperties={{
                  jobId,
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }}
                IconComponent={DeleteIcon}
                text={getText('tabs_delete_job')}
                width='50%'
                onClick={() => setShowDeleteJob(true)}
                extraStyles={{ marginLeft: '32px' }}
              />
            </ButtonWrapper>
          )}
        </TabPanel>
      )}
      {activeTabKey === TabKeys.HAZARDS_CONTROLS && (
        <TabPanel>
          <HazardsControlsComments />
        </TabPanel>
      )}
      {activeTabKey === TabKeys.ASSESSMENTS && (
        <TabPanel>
          <AssessmentsList />
        </TabPanel>
      )}
      {activeTabKey === TabKeys.EVIDENCE && (
        <TabPanel>
          <EvidenceList />
        </TabPanel>
      )}
      {activeTabKey === TabKeys.JOB_BLOCKERS && (
        <TabPanel>
          <JobBlockerList />
        </TabPanel>
      )}
      {activeTabKey === TabKeys.DOCUMENTS && (
        <TabPanel>
          <DocumentList />
        </TabPanel>
      )}
      {activeTabKey === TabKeys.WORK_COMPLETED && (
        <TabPanel>
          <WorkOrderItemList />
        </TabPanel>
      )}
      {activeTabKey === TabKeys.LOGS && (
        <TabPanel>
          <LogsList />
        </TabPanel>
      )}
      {activeTabKey === TabKeys.PERMITS && (
        <TabPanel>
          <PermitList />
        </TabPanel>
      )}
    </>
  );
};
