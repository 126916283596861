import {
  SET_CURRENT_INSIGHTS_TAB,
  FETCH_ENGAGEMENT_DATA,
  FETCH_ENGAGEMENT_DATA_SUCCESS,
  FETCH_ENGAGEMENT_DATA_ERROR,
  FETCH_COMPLIANCE_DATA,
  FETCH_COMPLIANCE_DATA_SUCCESS,
  FETCH_COMPLIANCE_DATA_ERROR,
  FETCH_JOBS_PER_DAY_GRAPHS,
  FETCH_JOBS_PER_DAY_GRAPHS_SUCCESS,
  FETCH_JOBS_PER_DAY_GRAPHS_ERROR,
  FETCH_JOBS_PER_DAY_STATS,
  FETCH_JOBS_PER_DAY_STATS_SUCCESS,
  FETCH_JOBS_PER_DAY_STATS_ERROR,
  FETCH_OUTPUT_PER_DAY_GRAPHS,
  FETCH_OUTPUT_PER_DAY_GRAPHS_SUCCESS,
  FETCH_OUTPUT_PER_DAY_GRAPHS_ERROR,
  FETCH_OUTPUT_PER_DAY_STATS,
  FETCH_OUTPUT_PER_DAY_STATS_SUCCESS,
  FETCH_OUTPUT_PER_DAY_STATS_ERROR,
  FETCH_INSIGHTS_JOB_BLOCKERS,
  FETCH_INSIGHTS_JOB_BLOCKERS_SUCCESS,
  FETCH_INSIGHTS_JOB_BLOCKERS_ERROR,
  FETCH_HAVS_COMPLIANCE,
  FETCH_HAVS_COMPLIANCE_SUCCESS,
  FETCH_HAVS_COMPLIANCE_ERROR,
  FETCH_VEHICLE_CHECK_COMPLIANCE,
  FETCH_VEHICLE_CHECK_COMPLIANCE_SUCCESS,
  FETCH_VEHICLE_CHECK_COMPLIANCE_ERROR,
  FETCH_VEHICLE_CHECK_USER_COMPLIANCE,
  FETCH_VEHICLE_CHECK_USER_COMPLIANCE_SUCCESS,
  FETCH_VEHICLE_CHECK_USER_COMPLIANCE_ERROR,
  SIGN_OFF_HAVS_COMPLIANCE,
  SIGN_OFF_HAVS_COMPLIANCE_SUCCESS,
  SIGN_OFF_HAVS_COMPLIANCE_ERROR,
  FETCH_USER_FORM,
  FETCH_USER_FORM_SUCCESS,
  FETCH_USER_FORM_ERROR,
  FETCH_JOB_FORM,
  FETCH_JOB_FORM_SUCCESS,
  FETCH_JOB_FORM_ERROR,
  FETCH_PERMIT_FORM,
  FETCH_PERMIT_FORM_SUCCESS,
  FETCH_PERMIT_FORM_ERROR,
} from '../actions/actionTypes';

const defaultState = {
  currentTab: null,
  isLoadingEngagement: false,
  engagementData: [],
  isLoadingCompliance: false,
  complianceData: {},
  isLoadingJobsPerDayData: false,
  jobsPerDayGraphs: {},
  jobsPerDayStats: {},
  isLoadingOutputPerDayData: false,
  outputPerDayGraphs: null,
  outputPerDayStats: null,
  isLoadingJobBlockers: false,
  jobBlockersGraphs: null,
  jobBlockersStats: null,
  isLoadingHavsCompliance: false,
  havsComplianceData: {},
  isLoadingVehicleCheckCompliance: false,
  vehicleCheckComplianceData: {},
  isLoadingVehicleCheckUserCompliance: false,
  vehicleCheckUserComplianceData: {},
  isLoadingUserForms: {},
  isLoadingJobForms: {},
  isLoadingPermitForms: {},
  userFormsData: {},
  jobFormsData: {},
  permitFormsData: {},
};

const insights = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CURRENT_INSIGHTS_TAB:
      return {
        ...state,
        currentTab: action.tab,
      };
    case FETCH_ENGAGEMENT_DATA:
      return {
        ...state,
        isLoadingEngagement: true,
      };
    case FETCH_ENGAGEMENT_DATA_SUCCESS:
      return {
        ...state,
        engagementData: action.data,
        isLoadingEngagement: false,
      };
    case FETCH_ENGAGEMENT_DATA_ERROR:
      return {
        ...state,
        engagementData: [],
        isLoadingEngagement: false,
      };

    case FETCH_COMPLIANCE_DATA:
      return {
        ...state,
        isLoadingCompliance: true,
      };
    case FETCH_COMPLIANCE_DATA_SUCCESS:
      return {
        ...state,
        complianceData: action.data,
        isLoadingCompliance: false,
      };
    case FETCH_COMPLIANCE_DATA_ERROR:
      return {
        ...state,
        complianceData: {},
        isLoadingCompliance: false,
      };

    case FETCH_JOBS_PER_DAY_GRAPHS:
      return {
        ...state,
        isLoadingJobsPerDayData: true,
      };
    case FETCH_JOBS_PER_DAY_GRAPHS_SUCCESS:
      return {
        ...state,
        jobsPerDayGraphs: {
          ...state.jobsPerDayGraphs,
          [action.typeId]: action.data,
        },
        isLoadingJobsPerDayData: false,
      };
    case FETCH_JOBS_PER_DAY_GRAPHS_ERROR:
      return {
        ...state,
        jobsPerDayGraphs: {},
        isLoadingJobsPerDayData: false,
      };

    case FETCH_JOBS_PER_DAY_STATS:
      return {
        ...state,
        isLoadingJobsPerDayData: true,
      };
    case FETCH_JOBS_PER_DAY_STATS_SUCCESS:
      return {
        ...state,
        jobsPerDayStats: {
          ...state.jobsPerDayStats,
          [action.typeId]: action.data,
        },
        isLoadingJobsPerDayData: false,
      };
    case FETCH_JOBS_PER_DAY_STATS_ERROR:
      return {
        ...state,
        jobsPerDayStats: {},
        isLoadingJobsPerDayData: false,
      };

    case FETCH_OUTPUT_PER_DAY_GRAPHS:
      return {
        ...state,
        isLoadingOutputPerDayData: true,
      };
    case FETCH_OUTPUT_PER_DAY_GRAPHS_SUCCESS:
      return {
        ...state,
        outputPerDayGraphs: action.data,
        isLoadingOutputPerDayData: false,
      };
    case FETCH_OUTPUT_PER_DAY_GRAPHS_ERROR:
      return {
        ...state,
        outputPerDayGraphs: null,
        isLoadingOutputPerDayData: false,
      };

    case FETCH_OUTPUT_PER_DAY_STATS:
      return {
        ...state,
        isLoadingOutputPerDayData: true,
      };
    case FETCH_OUTPUT_PER_DAY_STATS_SUCCESS:
      return {
        ...state,
        outputPerDayStats: action.data,
        isLoadingOutputPerDayData: false,
      };
    case FETCH_OUTPUT_PER_DAY_STATS_ERROR:
      return {
        ...state,
        outputPerDayStats: null,
        isLoadingOutputPerDayData: false,
      };

    case FETCH_INSIGHTS_JOB_BLOCKERS:
      return {
        ...state,
        isLoadingJobBlockers: true,
      };
    case FETCH_INSIGHTS_JOB_BLOCKERS_SUCCESS:
      return {
        ...state,
        jobBlockersGraphs: action.data.map((result) => ({
          ...result,
          timeLostCurrentPeriod: result.timeLostCurrentPeriod,
          timeLostPreviousPeriod: result.timeLostPreviousPeriod,
        })),
        jobBlockersStats: action.data
          .map((result) => ({
            ...result,
            timeLostCurrentPeriod: result.timeLostCurrentPeriod,
            timeLostPreviousPeriod: result.timeLostPreviousPeriod,
          }))
          .slice(0, 3),

        isLoadingJobBlockers: false,
      };
    case FETCH_INSIGHTS_JOB_BLOCKERS_ERROR:
      return {
        ...state,
        jobBlockersStats: null,
        jobBlockersGraphs: null,
        isLoadingJobBlockers: false,
      };

    case FETCH_HAVS_COMPLIANCE:
      return {
        ...state,
        isLoadingHavsCompliance: true,
      };
    case FETCH_HAVS_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isLoadingHavsCompliance: false,
        havsComplianceData: action.data,
      };
    case FETCH_HAVS_COMPLIANCE_ERROR:
      return {
        ...state,
        isLoadingHavsCompliance: false,
        havsComplianceData: {},
      };
    case SIGN_OFF_HAVS_COMPLIANCE:
      return {
        ...state,
        isLoadingHavsCompliance: true,
      };
    case SIGN_OFF_HAVS_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isLoadingHavsCompliance: false,
        havsComplianceData: {
          ...state.havsComplianceData,
          results: state.havsComplianceData.results?.map((result) => {
            if (result.user_id === action.userId) {
              return {
                ...result,
                signed_off: [action.data, ...result.signed_off],
                requiring_sign_off: !result.requiring_sign_off,
              };
            }
            return result;
          }),
        },
      };
    case SIGN_OFF_HAVS_COMPLIANCE_ERROR:
      return {
        ...state,
        isLoadingHavsCompliance: false,
      };

    case FETCH_VEHICLE_CHECK_COMPLIANCE:
      return {
        ...state,
        isLoadingVehicleCheckCompliance: true,
      };
    case FETCH_VEHICLE_CHECK_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isLoadingVehicleCheckCompliance: false,
        vehicleCheckComplianceData: action.data,
      };
    case FETCH_VEHICLE_CHECK_COMPLIANCE_ERROR:
      return {
        ...state,
        isLoadingVehicleCheckCompliance: false,
        vehicleCheckComplianceData: {},
      };

    case FETCH_VEHICLE_CHECK_USER_COMPLIANCE:
      return {
        ...state,
        isLoadingVehicleCheckUserCompliance: true,
      };
    case FETCH_VEHICLE_CHECK_USER_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isLoadingVehicleCheckUserCompliance: false,
        vehicleCheckUserComplianceData: action.data,
      };
    case FETCH_VEHICLE_CHECK_USER_COMPLIANCE_ERROR:
      return {
        ...state,
        isLoadingVehicleCheckUserCompliance: false,
        vehicleCheckUserComplianceData: {},
      };

    case FETCH_USER_FORM:
      return {
        ...state,
        isLoadingUserForms: {
          ...state.isLoadingUserForms,
          [action.formId]: true,
        },
      };
    case FETCH_USER_FORM_SUCCESS:
      return {
        ...state,
        isLoadingUserForms: {
          ...state.isLoadingUserForms,
          [action.formId]: false,
        },
        userFormsData: {
          ...state.userFormsData,
          [action.formId]: { ...action.data },
        },
      };
    case FETCH_USER_FORM_ERROR:
      return {
        ...state,
        isLoadingUserForms: {
          ...state.isLoadingUserForms,
          [action.formId]: false,
        },
      };
    case FETCH_JOB_FORM:
      return {
        ...state,
        isLoadingJobForms: {
          ...state.isLoadingJobForms,
          [action.formId]: true,
        },
      };
    case FETCH_JOB_FORM_SUCCESS:
      return {
        ...state,
        isLoadingJobForms: {
          ...state.isLoadingJobForms,
          [action.formId]: false,
        },
        jobFormsData: {
          ...state.jobFormsData,
          [action.formId]: { ...action.data },
        },
      };
    case FETCH_JOB_FORM_ERROR:
      return {
        ...state,
        isLoadingJobForms: {
          ...state.isLoadingJobForms,
          [action.formId]: false,
        },
      };
    case FETCH_PERMIT_FORM:
      return {
        ...state,
        isLoadingPermitForms: {
          ...state.isLoadingPermitForms,
          [action.formId]: true,
        },
      };
    case FETCH_PERMIT_FORM_SUCCESS:
      return {
        ...state,
        isLoadingPermitForms: {
          ...state.isLoadingPermitForms,
          [action.formId]: false,
        },
        permitFormsData: {
          ...state.permitFormsData,
          [action.formId]: { ...action.data },
        },
      };
    case FETCH_PERMIT_FORM_ERROR:
      return {
        ...state,
        isLoadingPermitForms: {
          ...state.isLoadingPermitForms,
          [action.formId]: false,
        },
      };
    default:
      return state;
  }
};

export default insights;
