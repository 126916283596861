import RootState from 'model/State/RootState';
import { useDispatch, useSelector } from 'react-redux';
import {
  EVENT_JOB_SORTED,
  EVENT_PROPERTIES_PAGE_SOURCE_FIELD,
  LIST_VIEW_TAB,
  jobV2Fields,
} from 'utils/constants';
import { useCallback } from 'react';
import { fetchJobs, setJobPinnedStatus, setJobSort } from 'store/actions/jobs';
import JobsTable from './JobsTable';
import Sort from 'model/Sort';
import { scrollToTop } from 'helpers/table';
import Team from 'model/Team';
import * as Analytics from 'utils/analytics';
import { SortOrder } from 'model/enum/SortOrder';
import { selectJobsView } from 'store/selectors';
import { EVENT_PAGE_CHANGE_CLICKED } from './constants';
import { ExportInfoModal } from '../FileExport/ExportInfoModal';

//JobsV2.ListV2
const ListV2 = ({ selectedTeam }: { selectedTeam: Team | null }) => {
  const dispatch = useDispatch();
  const { filters, sort } = useSelector((state: RootState) => state.jobs);
  const view = useSelector(selectJobsView);

  const getJobsPerPage = useCallback(
    (page: number) => {
      Analytics.trackEvent(EVENT_PAGE_CHANGE_CLICKED, {
        page,
        filters,
        [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      });
      dispatch(fetchJobs(filters, LIST_VIEW_TAB, page));
      scrollToTop();
    },
    [dispatch, filters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onSortChange = useCallback(
    (newSort: Sort) => {
      dispatch(setJobSort(newSort));
      scrollToTop();
      Analytics.trackEvent(EVENT_JOB_SORTED, {
        sortBy: newSort?.field,
        order: newSort?.order,
        [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      });
    },
    [dispatch, view],
  );

  const sortBasedOnSearchQuery = filters?.q
    ? { field: jobV2Fields.ACTION_REQUIRED, order: SortOrder.DESCENDING }
    : sort;

  const updatePinnedStatusInStore = (jobId: number, status: boolean) =>
    dispatch(setJobPinnedStatus(jobId, status));

  return (
    <>
      <JobsTable
        onRequestPage={getJobsPerPage}
        updatePinnedStatusInStore={updatePinnedStatusInStore}
        sort={sortBasedOnSearchQuery}
        onSortChange={onSortChange}
        selectedTeam={selectedTeam}
        sortingDisabled={!!filters?.q}
      />

      <ExportInfoModal />
    </>
  );
};

export default ListV2;
