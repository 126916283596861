import styled from 'styled-components';
import theme from 'theme';
import { useText } from 'hooks';
import { InsightSummaryStatChange } from './InsightSummaryStatChange';
import { roundPercentage, roundTo1Decimal } from 'helpers/number';

const { colors, fonts, fontSizes } = theme;

// Styled Components
const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 16px;
  height: 220px;
  flex-direction: column;
  border-bottom: 1px solid ${colors.lightGrey};
  border-top: 3px solid ${colors.black};
  background-color: ${colors.white};
`;

const SummaryTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const SummaryTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  text-align: center;
`;

const JobBlockerTitle = styled.div`
  font-family: ${fonts.italic};
  font-size: ${fontSizes.m};
  text-align: center;
  padding-top: 10px;
`;

const SummaryNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
`;

const SummaryNumber = styled.div<{ $isRed: boolean }>`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.massive};
  color: ${({ $isRed }) => ($isRed ? colors.red : colors.black)};
`;

type Props = {
  title: string;
  currentData: number;
  previousData: number;
  totalJobNumber?: number;
  isJobBlocker?: boolean;
};

const InsightSummaryItem = ({
  title,
  currentData,
  previousData,
  totalJobNumber,
  isJobBlocker = false,
}: Props): JSX.Element => {
  const getText = useText();
  const percentageChange: number = (currentData - previousData) / previousData;
  const isDecrease: boolean = percentageChange < 0;

  const renderNumberData = () => {
    if (totalJobNumber) {
      return <>{roundPercentage(100 * (currentData / totalJobNumber))}%</>;
    }
    if (isJobBlocker) {
      return `${Math.floor(currentData / 60)}${getText('insights_hours')} ${currentData % 60}${getText('insights_minutes')}`;
    }
    return roundTo1Decimal(currentData);
  };

  return (
    <SummaryItem>
      <SummaryTitleWrapper>
        {isJobBlocker ? (
          <JobBlockerTitle>{title}</JobBlockerTitle>
        ) : (
          <SummaryTitle>{title}</SummaryTitle>
        )}
      </SummaryTitleWrapper>
      <SummaryNumberWrapper>
        <SummaryNumber $isRed={isDecrease}>{renderNumberData()}</SummaryNumber>
        <InsightSummaryStatChange
          previousData={previousData}
          percentageChange={percentageChange}
        />
      </SummaryNumberWrapper>
    </SummaryItem>
  );
};

export default InsightSummaryItem;
