import {
  Header,
  HeaderTop,
  JobNumber,
  Address,
  StatusContainer,
  JobTimelineContainer,
} from '../styled';
import { useText } from 'hooks';

import { JobMap } from 'components/ui/JobMap';
import { LiveJobWeather } from 'components/ui/LiveJobWeather';

import { MobileHeaderLinks } from './MobileHeaderLinks';
import { JobOverviewMobileTabSection } from './JobOverviewMobileTabSection';
import HighRiskWarning from './HighRiskWarning';
import { displayFullAddress } from '../helpers';
import { JobTimeline } from 'components/ui/JobTimeline';
import { DeleteJob } from 'components/ui/Modal/DeleteJob';
import { StatusSelect } from './StatusSelect';
import theme from 'theme';

export const MobileView = ({
  activeJob,
  statuses,
  jobId,
  showDeleteJob,
  setShowDeleteJob,
  isStreamline,
  permitsEnabled,
  isManager,
  isBackOffice,
}): JSX.Element => {
  const getText = useText();

  return (
    <>
      {activeJob.status && statuses && (
        <StatusContainer>
          <StatusSelect
            label={getText('status')}
            labelColor={theme.colors.white}
            large
            noBorder
          />
        </StatusContainer>
      )}
      <JobMap job={activeJob} />
      <LiveJobWeather job={activeJob} />
      {showDeleteJob && (
        <DeleteJob showModal={showDeleteJob} setShowModal={setShowDeleteJob} />
      )}
      <Header>
        <HeaderTop>
          <div>
            <JobNumber>{activeJob.job_number}</JobNumber>
            <Address>{displayFullAddress(activeJob)}</Address>
          </div>
        </HeaderTop>
        <HighRiskWarning />
        <JobTimelineContainer isMobile>
          <JobTimeline
            isMobile
            jobStatusTitle={activeJob.status?.title}
            createdAt={activeJob.created_at}
            startedAt={activeJob.started_at}
            completedAt={activeJob.completed_at}
          />
        </JobTimelineContainer>
        <MobileHeaderLinks
          jobId={jobId}
          isStreamline={isStreamline}
          permitsEnabled={permitsEnabled}
        />
      </Header>
      <JobOverviewMobileTabSection
        jobId={jobId}
        activeJob={activeJob}
        isManager={isManager}
        isBackOffice={isBackOffice}
        setShowDeleteJob={setShowDeleteJob}
      />
    </>
  );
};
