import * as constants from 'utils/constants';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsFetchingPermits,
  selectJobPermits,
  selectJobsView,
} from 'store/selectors';
import { InformationLabel } from 'components/ui/Shared/EmptyState';
import { Card, Loader } from 'components/ui';
import { useEffect } from 'react';
import { useText } from 'hooks';
import OverviewTabBase from './OverviewTabBase';
import { Permit } from 'assets/icons';
import { fetchPermits } from 'store/actions/jobs';
import PermitStatus from '../../Permits/PermitStatus';
import { DownloadType } from '../../FileExport/constants';
import { CustomFormClass } from 'model/Form';

export const Permits = (): JSX.Element => {
  const { jobId } = useParams();
  const permits = useSelector(selectJobPermits(jobId));
  const isFetchingPermits = useSelector(selectIsFetchingPermits);
  const view = useSelector(selectJobsView);

  const getText = useText();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPermits(jobId));
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OverviewTabBase title={getText('tabs_permits')}>
      <>
        {isFetchingPermits ? (
          <Loader />
        ) : (
          <>
            {!permits?.length ? (
              <InformationLabel>
                {getText('permit_no_display')}
              </InformationLabel>
            ) : (
              permits?.map((permit) => (
                <>
                  <Card.JobDetail
                    event={constants.EVENT_VIEW_PERMIT_ITEM}
                    eventProperties={{
                      jobId: jobId,
                      permitId: permit.id,
                      permitType: permit.type
                        ? permit.type.title
                        : getText('job_permit'),
                      source: 'job-overview',
                      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                    }}
                    key={`permit-${permit.id}`}
                    title={getText('job_permit')}
                    subtitle={permit?.type?.title}
                    creator={permit.createdBy}
                    date={permit.createdAt}
                    to={`/jobs/${permit.jobId}/permits/${permit.id}`}
                    IconComponent={Permit}
                    jobId={jobId}
                    downloadItemId={permit.id}
                    downloadType={DownloadType.PERMIT}
                    formClass={CustomFormClass.PERMIT}
                  />
                  <PermitStatus
                    key={`permit-status-${permit.id}`}
                    lastApproval={permit.lastApproval}
                  />
                </>
              ))
            )}
          </>
        )}
      </>
    </OverviewTabBase>
  );
};
