import {
  Header,
  HeaderTop,
  JobNumber,
  Address,
  StatusContainer,
  JobTimelineContainer,
} from '../styled';
import { Tabs } from 'components/ui';
import { useText } from 'hooks';

import { JobMap } from 'components/ui/JobMap';
import { LiveJobWeather } from 'components/ui/LiveJobWeather';
import * as constants from 'utils/constants';

import { JobOverviewDesktopTabSection } from './JobOverviewDesktopTabSection';
import HighRiskWarning from './HighRiskWarning';
import { displayFullAddress } from '../helpers';
import { JobTimeline } from 'components/ui/JobTimeline';
import { DeleteJob } from 'components/ui/Modal/DeleteJob';
import { StatusSelect } from './StatusSelect';

export const DesktopView = ({
  activeJob,
  statuses,
  canUseListView,
  jobId,
  showDeleteJob,
  setShowDeleteJob,
  isStreamline,
  permitsEnabled,
  isManager,
  isBackOffice,
}): JSX.Element => {
  const getText = useText();

  return (
    <>
      {canUseListView && (
        <Tabs.JobView
          activeTab={constants.LEGACY_VIEW}
          extraStyles={{
            position: 'absolute',
            width: 'fit-content',
            right: '50px',
            top: '220px',
            zIndex: '10',
          }}
        />
      )}
      <JobMap job={activeJob} />
      <LiveJobWeather job={activeJob} />
      {showDeleteJob && (
        <DeleteJob showModal={showDeleteJob} setShowModal={setShowDeleteJob} />
      )}
      <Header>
        <HeaderTop>
          <div>
            <JobNumber>{activeJob.job_number}</JobNumber>
            <Address>{displayFullAddress(activeJob)}</Address>
          </div>

          {activeJob.status && statuses && (
            <StatusContainer>
              <StatusSelect label={getText('status')} />
            </StatusContainer>
          )}
        </HeaderTop>
        <HighRiskWarning />
        <JobTimelineContainer>
          <JobTimeline
            jobStatusTitle={activeJob.status?.title}
            createdAt={activeJob.created_at}
            startedAt={activeJob.started_at}
            completedAt={activeJob.completed_at}
          />
        </JobTimelineContainer>
      </Header>
      <JobOverviewDesktopTabSection
        jobId={jobId}
        isStreamline={isStreamline}
        permits_enabled={permitsEnabled}
        activeJob={activeJob}
        isManager={isManager}
        isBackOffice={isBackOffice}
        setShowDeleteJob={setShowDeleteJob}
      />
    </>
  );
};
