import { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { useJobs, useQueryParams, useText } from 'hooks';
import { Button, Loader, Media, Modal } from 'components/ui';
import theme from 'theme';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Storage } from 'aws-amplify';
import { getVideo, hasVideo } from 'helpers/forms';
import Rejection from './Modal/Rejection';
import SetDuration from './Modal/SetDuration';
import PermitStatus from './PermitStatus';
import RejectionComment from './Modal/RejectionComment';
import PermitDetails from './Modal/PermitDetails';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsFetchingPermits,
  selectJobsView,
  selectPermit,
} from 'store/selectors';
import { fetchPermits } from 'store/actions/jobs';
import { ModalFooter } from '../FileExport/ModalFooter';
import { DownloadType } from '../FileExport/constants';
import { CustomFormClass } from 'model/Form';
import { Instruction } from './Modal/Instruction';

const { breakpoints, colors, mixins } = theme;

const Container = styled.div`
  height: 80vh;
  display: flex;
`;

const RightContainer = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  background-color: ${colors.white};
  ${mixins.flexFullCenter};
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SectionWrapper = styled.div`
  background-color: ${colors.white};
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Section = styled.div`
  padding-top: 16px;
  border-bottom: 15px solid ${colors.offWhite};
  &:last-of-type {
    border: none;
  }
  @media only screen and (max-width: ${breakpoints.sm}px) {
    padding: 20px 15px 40px 15px;
  }
`;

const RejectionButtonContainer = styled.div`
  padding: 0 25px 40px 25px;
`;

export default () => {
  const navigate = useNavigate();
  const { jobId, permitId } = useParams();
  const permit = useSelector(selectPermit(jobId, permitId));
  const isFetchingPermits = useSelector(selectIsFetchingPermits);
  const view = useSelector(selectJobsView);

  const getText = useText();
  const dispatch = useDispatch();
  const { navigateToOverviewTabOrJob } = useJobs();
  const { getPathWithQueryParams } = useQueryParams();
  const { pathname } = useLocation();
  const [videoUrl, setVideoUrl] = useState<string>();

  const handleGoBack = () => {
    navigate(getPathWithQueryParams(`/jobs/${jobId}/permits/${permitId}`));
  };

  useEffect(() => {
    if (!permit) {
      dispatch(fetchPermits(jobId));
    }
    if (permitId && jobId && permit) {
      Analytics.trackPage(constants.EVENT_VIEW_PERMIT_ITEM, {
        jobId,
        permitId,
        mediaPath: [
          permit?.photos?.map((x) => x.path),
          permit?.videos?.map((x) => x.path),
        ],
      });
    }
  }, [permitId, jobId, permit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      if (permit?.form && hasVideo(permit?.form)) {
        const signedURL = await Storage.get(
          permit?.form?.sections[0]?.fields[0]?.value.path,
        );
        setVideoUrl(signedURL);
      }
    })();
  }, [permit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal.Base
      isOpen
      title={permit ? permit?.type?.title : getText('job_permit')}
      onClose={() => navigateToOverviewTabOrJob(`/jobs/${jobId}/permits`)}
      onBack={
        ((pathname.includes('rejection') ||
          pathname.includes('set-duration') ||
          pathname.includes('instruction')) &&
          handleGoBack) ||
        null
      }
      width={hasVideo(permit?.form) ? '80vw' : '45vw'}
    >
      <Container>
        {isFetchingPermits ? (
          <Loader />
        ) : (
          <>
            {hasVideo(permit?.form) && videoUrl && (
              <Media
                kind='permit'
                type='video'
                url={videoUrl}
                width='55%'
                item={getVideo(permit?.form, permit?.videos)}
              />
            )}
            <Routes>
              <Route
                path='/rejection'
                element={
                  <RightContainer
                    width={hasVideo(permit?.form) ? '45%' : '100%'}
                  >
                    <Rejection />
                  </RightContainer>
                }
              />
              <Route
                path='/set-duration'
                element={
                  <RightContainer
                    width={hasVideo(permit?.form) ? '45%' : '100%'}
                  >
                    <SetDuration />
                  </RightContainer>
                }
              />
              <Route
                path='instruction/:fieldId'
                element={
                  <RightContainer
                    width={hasVideo(permit?.form) ? '45%' : '100%'}
                  >
                    <Instruction />
                  </RightContainer>
                }
              />
              <Route
                path='/'
                element={
                  <RightContainer
                    width={hasVideo(permit?.form) ? '45%' : '100%'}
                  >
                    <ContentWrapper>
                      <SectionWrapper>
                        <Section>
                          <PermitStatus
                            lastApproval={permit?.lastApproval}
                            extraStyles={{
                              borderRadius: '3px',
                              margin: '0 25px 40px 25px',
                            }}
                          />
                          {permit?.lastApproval &&
                            !permit.lastApproval.isApproved && (
                              <RejectionComment
                                lastApproval={permit.lastApproval}
                                extraStyles={{
                                  padding: '0 25px 40px 25px',
                                }}
                              />
                            )}
                          {permit && <PermitDetails permit={permit} />}
                          {!permit?.lastApproval && (
                            <RejectionButtonContainer>
                              <Button.SecondaryLink
                                text={getText(
                                  'job_permits_reject_with_comment',
                                )}
                                to={getPathWithQueryParams(
                                  `/jobs/${jobId}/permits/${permitId}/rejection`,
                                )}
                                event={
                                  constants.EVENT_PERMIT_REJECT_BUTTON_CLICKED
                                }
                                eventProperties={{
                                  jobId,
                                  permitId,
                                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]:
                                    view,
                                }}
                              />
                            </RejectionButtonContainer>
                          )}
                        </Section>
                      </SectionWrapper>
                      <ModalFooter
                        downloadType={DownloadType.PERMIT}
                        downloadItemId={permit?.id.toString()}
                        jobId={permit?.jobId}
                        formClass={CustomFormClass.PERMIT}
                      />
                      <Button.Primary
                        text={getText(
                          permit?.lastApproval
                            ? 'job_permits_go_to_timeline_button'
                            : 'job_permits_set_duration',
                        )}
                        extraStyles={{
                          position: 'sticky',
                          bottom: '0',
                          marginTop: 'auto',
                          flex: '0 0 55px',
                        }}
                        onClick={() =>
                          navigate(
                            getPathWithQueryParams(
                              permit?.lastApproval
                                ? `/jobs/${jobId}`
                                : `/jobs/${jobId}/permits/${permitId}/set-duration`,
                            ),
                          )
                        }
                        event={
                          permit?.lastApproval
                            ? constants.EVENT_BACK_TO_TIMELINE
                            : constants.EVENT_PERMIT_SET_DURATION_BUTTON_CLICKED
                        }
                        eventProperties={{
                          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                        }}
                      />
                    </ContentWrapper>
                  </RightContainer>
                }
              />
            </Routes>
          </>
        )}
      </Container>
    </Modal.Base>
  );
};
