import { put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { Messages } from 'api';
import { fetchAssessments, fetchAssessment } from 'store/actions/assessments';
import {
  receiveMessage,
  updateMessages,
  appendMessages,
} from '../actions/messages';
import {
  fetchActiveJob,
  fetchJobs,
  processUnreadMessage,
  updateJob,
} from '../actions/jobs';
import {
  WEBSOCKET_MESSAGE_RECEIVED,
  GET_MESSAGES,
  GET_MORE_MESSAGES,
  SET_REACTION,
} from '../actions/actionTypes';
import {
  setFileDownloadInfo,
  setGlobalError,
} from 'store/slices/notifications';
import { selectJobsView } from 'store/selectors';
import { selectActiveRamsAssessment } from 'store/slices/ramsAssessments';
import { selectActiveAssessment } from 'store/selectors/assessments';
import * as Analytics from 'utils/analytics';
import { EVENT_DOWNLOAD_TOAST_POPUP } from 'components/views/Protected/Jobs/FileExport/constants';
import { getFileExportDataFromLocalStorage } from 'helpers/files';
import { MessageType } from 'model/Message';
import { partialMapToPermit } from 'api/Permit/helpers';

function* fetchMessages({ jobId }) {
  try {
    const data = yield Messages.getMessages(jobId);
    yield put(updateMessages(data.results.reverse(), data.next, Number(jobId)));
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* fetchMoreMessages({ nextPage }) {
  try {
    const data = yield Messages.getMoreMessages(nextPage);
    yield put(appendMessages(data.results.reverse(), data.next));
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* messageReceived(action) {
  const data = JSON.parse(action.data);
  const { activeJob } = yield select((state) => state.jobs);
  if (data.type === 'job_message') {
    const activeAssessment = yield select(selectActiveAssessment);
    const activeRamsAssessment = yield select(selectActiveRamsAssessment);
    const isActiveJobMessage = activeJob && activeJob.id === data.target.job_id;

    if (isActiveJobMessage) {
      if (data.target.type === MessageType.ASSESSMENT) {
        yield put(fetchAssessments(activeJob.id));
        const isActiveAssessmentMessage =
          activeAssessment && activeAssessment?.id === data.target.target?.id;
        const isActiveRamsAssessmentMessage =
          activeRamsAssessment &&
          activeRamsAssessment?.id === data.target.target?.id;

        if (
          (isActiveAssessmentMessage || isActiveRamsAssessmentMessage) &&
          !data.target.target?.is_signed_off
        ) {
          yield put(fetchAssessment(data.target.target?.id));
        }
      } else if (data.target.type === MessageType.EVENT) {
        yield put(fetchActiveJob(activeJob.id));
      }

      if (data.target.type === MessageType.PERMIT) {
        yield put(receiveMessage(partialMapToPermit(data.target)));
      } else {
        yield put(receiveMessage(data.target));
      }
    } else if (data.target.type === MessageType.TEXT) {
      yield put(processUnreadMessage(data.target.job_id, data.target.id));
    }

    if (
      data.target.type === MessageType.LOG ||
      data.target.type === MessageType.ASSESSMENT ||
      (!isActiveJobMessage && data.target.type === 'text')
    ) {
      yield put(updateJob(data.target.job_id));
    }
  } else if (data.type === 'alert') {
    if (data.target.type === 'AssignedToJob') {
      const { filters } = yield select((state) => state.jobs);
      const view = yield select(selectJobsView);
      yield put(fetchJobs(filters, view));
    }
    if (data.target.type === 'JobSafetyPredictionScoreUpdated') {
      yield put(updateJob(data.target?.target?.job_id));
      if (activeJob?.id === data.target?.target?.job_id) {
        yield put(fetchActiveJob(activeJob.id));
      }
    }
    if (
      data.target.type === 'SilentAlert' &&
      data.target.target.title === 'DownloadReady'
    ) {
      yield put(
        setFileDownloadInfo({
          objectId: data.target.target.object_id,
          fileName: data.target.target.file_name,
        }),
      );
      Analytics.trackEvent(EVENT_DOWNLOAD_TOAST_POPUP, {
        ...getFileExportDataFromLocalStorage(data.target.target.object_id),
      });
    }
  }
}

function* setReaction({ messageId, reactionType }) {
  try {
    yield Messages.setReaction(messageId, reactionType);
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

export default function* messagesWatcher() {
  yield takeLatest(GET_MESSAGES, fetchMessages);
  yield takeLatest(GET_MORE_MESSAGES, fetchMoreMessages);
  yield takeLatest(SET_REACTION, setReaction);
  yield takeEvery(WEBSOCKET_MESSAGE_RECEIVED, messageReceived);
}
