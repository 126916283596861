import { createSelector } from 'reselect';
import RootState from 'model/State/RootState';
import { Form } from 'model/Form';
import { selectCurrentUserId } from './session';
import { WorkOrderItem } from 'model/WorkOrderItem';

export const selectJobs = (state: RootState) => state.jobs;
export const selectLogs = (state) => state.jobs.logsByJob;
export const selectIsFetchingLogs = (state) => state.jobs.isFetchingLogs;
const selectLogsByJobId = createSelector(
  selectLogs,
  (_, jobId: string | undefined) => jobId,
  (logs, jobId: string | undefined) => (logs && jobId ? logs[jobId] : []),
);
export const selectJobLogs = (jobId: string | undefined) => (state) =>
  selectLogsByJobId(state, jobId);
export const selectAllJobBlockers = (state) => state.jobs.jobBlockersByJob;
export const selectIsFetchingJobBlockers = (state) =>
  state.jobs.isFetchingJobBlockers;
const selectJobBlockersByJobId = createSelector(
  selectAllJobBlockers,
  (_, jobId: string | undefined) => jobId,
  (blockers, jobId: string | undefined) =>
    blockers && jobId ? blockers[jobId] : [],
);
export const selectJobBlockers = (jobId: string | undefined) => (state) =>
  selectJobBlockersByJobId(state, jobId);
export const selectDocuments = (state: RootState) => state.jobs.documentsByJob;
export const selectIsFetchingDocuments = (state: RootState) =>
  state.jobs.isFetchingDocuments;
const selectDocumentsByJobId = createSelector(
  selectDocuments,
  (_: RootState, jobId: string | undefined) => jobId,
  (documents, jobId: string | undefined) =>
    documents && jobId ? documents[jobId] : [],
);
export const selectJobDocuments =
  (jobId: string | undefined) => (state: RootState) =>
    selectDocumentsByJobId(state, jobId);
export const selectWorkOrderItems = (state: RootState) =>
  state.jobs.workOrderItemsByJob;
export const selectIsFetchingWorkOrderItems = (state: RootState) =>
  state.jobs.isFetchingWorkOrderItems;
const selectWorkOrderItemsByJobId = createSelector(
  selectWorkOrderItems,
  (_: RootState, jobId: string | undefined) => jobId,
  (workOrderItems, jobId) =>
    workOrderItems && jobId ? workOrderItems[jobId] : [],
);
export const selectJobWorkOrderItems =
  (jobId: string | undefined) =>
  (state: RootState): WorkOrderItem[] =>
    selectWorkOrderItemsByJobId(state, jobId);
export const selectPermits = (state: RootState) => state.jobs.permitsByJob;
export const selectIsFetchingPermits = (state: RootState) =>
  state.jobs.isFetchingPermits;
const selectPermitsByJobId = createSelector(
  selectPermits,
  (_: RootState, jobId: string | undefined) => jobId,
  (permits, jobId: string | undefined) =>
    permits && jobId ? permits[jobId] : [],
);
export const selectJobPermits =
  (jobId: string | undefined) =>
  (state: RootState): Form[] =>
    selectPermitsByJobId(state, jobId);
const selectPermitById = createSelector(
  selectPermits,
  (_: RootState, jobId: string | undefined, __: string | undefined) => jobId,
  (_: RootState, __: string | undefined, permitId: string | undefined) =>
    permitId,
  (permits, jobId: string | undefined, permitId: string | undefined) =>
    permits && jobId && permitId
      ? permits?.[jobId]?.find(
          (permit: Form) => permit?.id === parseInt(permitId),
        )
      : null,
);
export const selectPermit =
  (jobId: string | undefined, permitId: string | undefined) =>
  (state: RootState): Form =>
    selectPermitById(state, jobId, permitId);

export const selectActiveJob = (state: RootState) => state.jobs.activeJob;
export const selectFilters = (state: RootState) => state.jobs.filters;
const selectNearbyJobPathByJobId = createSelector(
  (state: RootState) => state.jobs.nearbyJobPath,
  (_: RootState, jobId: string | undefined) => jobId,
  (nearbyJobPath, jobId: string | undefined) =>
    jobId ? nearbyJobPath?.[jobId] : null,
);
export const selectNearbyJobPath =
  (jobId: string | undefined) => (state: RootState) =>
    selectNearbyJobPathByJobId(state, jobId);
const selectJobTimelinePathById = createSelector(
  (state: RootState) => state.jobs.previousTimelinePath,
  (_: RootState, jobId: string | undefined) => jobId,
  (previousTimelinePath, jobId: string | undefined) =>
    jobId ? previousTimelinePath?.[jobId] : null,
);
export const selectPreviousTimelinePath =
  (jobId: string | undefined) => (state: RootState) =>
    selectJobTimelinePathById(state, jobId);
export const selectIsCurrentUserAssignedToJob = (state: RootState): boolean => {
  const currentUserId = selectCurrentUserId(state);
  return (
    !!state.jobs.activeJob &&
    !!state.jobs.activeJob?.assignees?.find((user) => user.id === currentUserId)
  );
};
export const selectIsAddingMeToActiveJob = (state: RootState) =>
  state.jobs.isAddingMeToActiveJob;
export const selectIsGeneratingZip = (state: RootState): boolean =>
  state.jobs.isGeneratingZip;
