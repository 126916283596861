import {
  INITIAL_FILTERS,
  LIST_VIEW,
  LIST_VIEW_TAB,
  MAP_VIEW_TAB,
  jobV2Fields,
} from 'utils/constants';
import {
  APPEND_DOCUMENT,
  APPEND_EVIDENCE,
  CLEAR_JOBS,
  FETCH_ACTIVE_JOB,
  FETCH_DOCUMENTS,
  FETCH_EVIDENCES,
  FETCH_LIST_JOBS,
  FETCH_WEATHER_ERROR,
  FETCH_WEATHER_FOR_JOB,
  RESET_ACTIVE_JOB,
  SET_ACTIVE_JOB,
  SET_DOCUMENTS,
  SET_EVIDENCES,
  SET_LIST_JOBS,
  SET_JOB_FILTERS,
  SET_JOB_PHOTOS,
  SET_JOB_STATS,
  SET_WEATHER_FOR_JOB,
  SET_JOB_SORT,
  UPDATE_USER_REVIEW_PSI_SCORE,
  FETCH_LOGS,
  SET_LOGS,
  FETCH_WORK_ORDER_ITEMS,
  SET_WORK_ORDER_ITEMS,
  FETCH_PERMITS,
  SET_PERMITS,
  UPDATE_PERMIT,
  FETCH_JOB_BLOCKERS,
  SET_JOB_BLOCKERS,
  FETCH_DOCUMENTS_ERROR,
  FETCH_LOGS_ERROR,
  FETCH_JOB_BLOCKERS_ERROR,
  FETCH_WORK_ORDER_ITEMS_ERROR,
  FETCH_PERMITS_ERROR,
  FETCH_EVIDENCES_ERROR,
  SET_PINNED_JOB_STATUS,
  SET_TAB,
  FETCH_MAP_JOBS,
  SET_MAP_JOBS,
  SET_ACTIVE_JOB_NEARBY_PATH,
  ADD_ME_TO_JOB,
  ADD_ME_TO_JOB_SUCCESS,
  ADD_ME_TO_JOB_ERROR,
  FETCH_JOB_STATS,
  SET_PREVIOUS_TIMELINE_PATH,
  PROCESS_UNREAD_MESSAGE,
  JOB_MARK_AS_READ,
  GENERATE_JOB_ZIP,
  GENERATE_JOB_ZIP_SUCCESS,
  GENERATE_JOB_ZIP_ERROR,
} from '../actions/actionTypes';
import JobState from 'model/State/JobState';
import { SortOrder } from 'model/enum/SortOrder';
import Job from 'model/JobData';

const defaultState: JobState = {
  activeJob: null,
  listJobs: null,
  mapJobs: null,
  total: 0,
  page: 1,
  evidences: [],
  logsByJob: {},
  jobBlockersByJob: {},
  documentsByJob: {},
  workOrderItemsByJob: {},
  permitsByJob: {},
  filters: INITIAL_FILTERS,
  sort: { field: jobV2Fields.LAST_ACTIVITY, order: SortOrder.DESCENDING },
  isFetchingActiveJob: false,
  isLoadingJobsPage: false,
  isLoadingJobStats: false,
  isFetchingJobs: false,
  isFetchingJobStats: false,
  jobStats: null,
  mapCanLoad: false,
  weather: null,
  isFetchingWeather: false,
  isEvidencesLoaded: false,
  isFetchingDocuments: false,
  isFetchingLogs: false,
  isFetchingJobBlockers: false,
  isFetchingWorkOrderItems: false,
  isFetchingPermits: false,
  isFirstLoad: false,
  nearbyJobPath: {},
  previousTimelinePath: {},
  isAddingMeToActiveJob: false,
  unreadMessageIdsByJobId: {},
  isGeneratingZip: false,
};

const jobs = (state = defaultState, action): JobState => {
  switch (action.type) {
    case FETCH_LIST_JOBS:
    case FETCH_MAP_JOBS:
      return {
        ...state,
        isFetchingJobs: true,
        isLoadingJobsPage: false,
        isLoadingJobStats: false,
        mapCanLoad: false,
        isFirstLoad: false,
      };
    case FETCH_JOB_STATS: {
      return { ...state, isFetchingJobStats: true };
    }
    case CLEAR_JOBS:
      return { ...defaultState };
    case FETCH_ACTIVE_JOB: {
      if (action.jobId === state?.activeJob?.id) {
        return {
          ...state,
          isFetchingActiveJob: true,
        };
      }

      return {
        ...state,
        isFetchingActiveJob: true,
        mapCanLoad: false,
        activeJob: null,
      };
    }
    case SET_TAB: {
      return {
        ...state,
        listJobs: null,
        mapJobs: null,
      };
    }
    case SET_LIST_JOBS:
      return {
        ...state,
        listJobs: [...action.jobs],
        total: action.count,
        page: action.page,
        isFetchingJobs: false,
        mapCanLoad: true,
      };
    case SET_MAP_JOBS:
      return {
        ...state,
        mapJobs: [...action.jobs],
        isFetchingJobs: false,
        mapCanLoad: true,
      };
    case SET_PINNED_JOB_STATUS:
      return {
        ...state,
        listJobs:
          state.listJobs?.map((job) => {
            if (job.id === action.jobId) {
              return { ...job, is_pinned: action.status };
            }
            return job;
          }) ?? null,
      };
    case SET_ACTIVE_JOB: {
      if (action.updateAllJobs) {
        const previousListJobs = state.listJobs ? [...state.listJobs] : [];
        const previousMapJobs = state.mapJobs ? [...state.mapJobs] : [];
        const existingListJobsIndex = previousListJobs.findIndex(
          (existingJob) => existingJob.id === action.activeJob.id,
        );
        const existingMapJobsIndex = previousMapJobs.findIndex(
          (existingJob) => existingJob.id === action.activeJob.id,
        );

        let listJobs = state.listJobs;
        let mapJobs = state.mapJobs;
        // If so, replace in place
        if (existingListJobsIndex > -1) {
          listJobs = previousListJobs.map((job) => {
            if (job.id === action.activeJob.id) {
              return {
                ...action.activeJob,
                last_activity:
                  action.activeJob.last_activity ?? job.last_activity,
              };
            }
            return job;
          });
        }
        if (existingMapJobsIndex > -1) {
          mapJobs = previousMapJobs.map((job) => {
            if (job.id === action.activeJob.id) {
              return action.activeJob;
            }
            return job;
          });
        }
        return {
          ...state,
          listJobs,
          mapJobs,
          activeJob: { ...state.activeJob, ...action.activeJob },
          isFetchingActiveJob: false,
          mapCanLoad: true,
        };
      }
      // Otherwise just append
      return {
        ...state,
        activeJob: { ...state.activeJob, ...action.activeJob },
        isFetchingActiveJob: false,
        mapCanLoad: true,
      };
    }
    case RESET_ACTIVE_JOB: {
      return {
        ...state,
        activeJob: null,
        nearbyJobPath: {},
        previousTimelinePath: {},
      };
    }
    case SET_ACTIVE_JOB_NEARBY_PATH: {
      if (action.activeJobId) {
        return {
          ...state,
          nearbyJobPath: {
            ...state.nearbyJobPath,
            [action.activeJobId]: action.nearbyJobPath,
          },
        };
      }
      return state;
    }
    case SET_PREVIOUS_TIMELINE_PATH: {
      if (action.activeJobId) {
        return {
          ...state,
          previousTimelinePath: {
            ...state.nearbyJobPath,
            [action.activeJobId]: action.previousTimelinePath,
          },
        };
      }
      return state;
    }
    case SET_JOB_FILTERS: {
      return {
        ...state,
        filters: action.filters,
        isLoadingJobsPage: true,
        isLoadingJobStats: true,
        isFirstLoad:
          action.newView === LIST_VIEW ||
          action.newView === LIST_VIEW_TAB ||
          action.newView === MAP_VIEW_TAB,
      };
    }
    case SET_JOB_SORT: {
      return { ...state, sort: action.sort };
    }
    case SET_JOB_STATS: {
      return { ...state, jobStats: action.jobStats, isFetchingJobStats: false };
    }
    case SET_WEATHER_FOR_JOB: {
      return { ...state, weather: action.weather, isFetchingWeather: false };
    }
    case FETCH_WEATHER_FOR_JOB: {
      if (action.jobId === state?.activeJob?.id) {
        return {
          ...state,
          isFetchingWeather: true,
        };
      }

      return { ...state, isFetchingWeather: true, weather: null };
    }
    case FETCH_WEATHER_ERROR: {
      return { ...state, weather: null, isFetchingWeather: false };
    }
    case FETCH_EVIDENCES: {
      return { ...state, isEvidencesLoaded: false };
    }
    case SET_EVIDENCES: {
      return { ...state, evidences: action.evidences, isEvidencesLoaded: true };
    }
    case FETCH_EVIDENCES_ERROR: {
      return { ...state, isEvidencesLoaded: true };
    }
    case APPEND_EVIDENCE: {
      return { ...state, evidences: [...state.evidences, action.evidence] };
    }
    case FETCH_DOCUMENTS: {
      return { ...state, isFetchingDocuments: true };
    }
    case SET_DOCUMENTS: {
      return {
        ...state,
        documentsByJob: {
          ...state.documentsByJob,
          [action.jobId]: [...action.documents],
        },
        isFetchingDocuments: false,
      };
    }
    case FETCH_DOCUMENTS_ERROR: {
      return { ...state, isFetchingDocuments: false };
    }
    case APPEND_DOCUMENT: {
      const jobDocuments = state.documentsByJob?.[action.jobId] || [];
      return {
        ...state,
        documentsByJob: {
          ...state.documentsByJob,
          [action.jobId]: [...jobDocuments, action.document],
        },
      };
    }
    case FETCH_LOGS: {
      return { ...state, isFetchingLogs: true };
    }
    case SET_LOGS: {
      return {
        ...state,
        logsByJob: {
          ...state.logsByJob,
          [action.jobId]: [...action.logs],
        },
        isFetchingLogs: false,
      };
    }
    case FETCH_LOGS_ERROR: {
      return { ...state, isFetchingLogs: false };
    }
    case FETCH_JOB_BLOCKERS: {
      return { ...state, isFetchingJobBlockers: true };
    }
    case SET_JOB_BLOCKERS: {
      return {
        ...state,
        jobBlockersByJob: {
          ...state.jobBlockersByJob,
          [action.jobId]: [...action.blockers],
        },
        isFetchingJobBlockers: false,
      };
    }
    case FETCH_JOB_BLOCKERS_ERROR: {
      return { ...state, isFetchingJobBlockers: false };
    }
    case FETCH_WORK_ORDER_ITEMS: {
      return { ...state, isFetchingWorkOrderItems: true };
    }
    case SET_WORK_ORDER_ITEMS: {
      return {
        ...state,
        workOrderItemsByJob: {
          ...state.workOrderItemsByJob,
          [action.jobId]: [...action.workOrderItems],
        },
        isFetchingWorkOrderItems: false,
      };
    }
    case FETCH_WORK_ORDER_ITEMS_ERROR: {
      return { ...state, isFetchingWorkOrderItems: false };
    }
    case FETCH_PERMITS: {
      return { ...state, isFetchingPermits: true };
    }
    case SET_PERMITS: {
      return {
        ...state,
        permitsByJob: {
          ...state.permitsByJob,
          [action.jobId]: [...action.permits],
        },
        isFetchingPermits: false,
      };
    }
    case FETCH_PERMITS_ERROR: {
      return { ...state, isFetchingPermits: false };
    }
    case UPDATE_PERMIT: {
      const jobPermits = state.permitsByJob?.[action.jobId] || [];
      const updatedPermits = jobPermits.map((permit) =>
        permit?.id === action.permit?.id ? action.permit : permit,
      );
      return {
        ...state,
        permitsByJob: {
          ...state.permitsByJob,
          [action.jobId]: [...updatedPermits],
        },
      };
    }
    case SET_JOB_PHOTOS:
      return {
        ...state,
        activeJob: {
          ...state.activeJob,
          photos: action.photos || [],
        } as Job,
      };
    case UPDATE_USER_REVIEW_PSI_SCORE:
      return {
        ...state,
        activeJob: {
          ...state.activeJob,
          user_review_psi_score_id: action.predictionId,
        } as Job,
      };
    case ADD_ME_TO_JOB:
      return {
        ...state,
        isAddingMeToActiveJob: true,
      };
    case ADD_ME_TO_JOB_SUCCESS:
      return {
        ...state,
        isAddingMeToActiveJob: false,
        activeJob: state.activeJob
          ? {
              ...state.activeJob,
              assignees: [...(state.activeJob?.assignees ?? []), action.userId],
            }
          : state.activeJob,
      };
    case ADD_ME_TO_JOB_ERROR:
      return {
        ...state,
        isAddingMeToActiveJob: false,
      };

    case PROCESS_UNREAD_MESSAGE: {
      const alreadyProcessedMessage = !!state.unreadMessageIdsByJobId[
        action.jobId
      ]?.find((id) => id === action.messageId);

      if (!alreadyProcessedMessage && state.listJobs) {
        return {
          ...state,
          listJobs: state.listJobs.map((job) => {
            if (job.id === action.jobId) {
              return { ...job, unread_messages: job.unread_messages + 1 };
            }
            return job;
          }),
          unreadMessageIdsByJobId: {
            ...state.unreadMessageIdsByJobId,
            [action.jobId]: [
              ...(state.unreadMessageIdsByJobId[action.jobId] ?? []),
              action.messageId,
            ],
          },
        };
      }
      return state;
    }

    case JOB_MARK_AS_READ: {
      const isShowingUnreadMessages =
        state.unreadMessageIdsByJobId[action.jobId]?.length > 0;

      if (isShowingUnreadMessages && state.listJobs) {
        return {
          ...state,
          listJobs: state.listJobs.map((job) => {
            if (job.id === action.jobId) {
              return { ...job, unread_messages: 0 };
            }
            return job;
          }),
          unreadMessageIdsByJobId: {
            ...state.unreadMessageIdsByJobId,
            [action.jobId]: [],
          },
        };
      }
      return state;
    }
    case GENERATE_JOB_ZIP:
      return { ...state, isGeneratingZip: true };
    case GENERATE_JOB_ZIP_SUCCESS:
    case GENERATE_JOB_ZIP_ERROR:
      return { ...state, isGeneratingZip: false };
    default:
      return state;
  }
};

export default jobs;
