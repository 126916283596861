import { put, select, takeLatest, throttle } from 'redux-saga/effects';
import { Assessment } from 'api';
import {
  getPdfExportIdError,
  getPdfExportIdSuccess,
  requestNewVraError,
  requestNewVraSuccess,
  setAssessment,
  setAssessmentError,
  setAssessmentSummaryError,
  setAssessmentSummarySuccess,
  setAssessments,
  setUpdatedAssessment,
} from 'store/actions/assessments';
import {
  FETCH_ASSESSMENTS,
  UPDATE_ASSESSMENT,
  FETCH_ASSESSMENT,
  SEND_SUGGESTION,
  SEND_ASSESSMENT_SUMMARY_FEEDBACK,
  REQUEST_NEW_VRA,
  GENERATE_ASSESSMENT_PDF,
} from '../actions/actionTypes';
import {
  setFileCreationToast,
  setGlobalError,
  showInfoModal,
} from 'store/slices/notifications';
import * as Logger from 'utils/logger';
import { setRamsAssessment } from 'store/slices/ramsAssessments';
import { selectCurrentUserId } from 'store/selectors';
import { setFileExportDataToLocalStorage } from 'helpers/files';
import { Storage } from 'aws-amplify';
import { ExtensionType } from 'model/FileExport';

function* fetchAssessments({ jobId }) {
  try {
    const assessments = yield Assessment.getAll(jobId);
    yield put(setAssessments(assessments));
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* editAssessment({ assessmentId, newAssessment }) {
  try {
    yield Assessment.editAssessment(assessmentId, newAssessment);
    yield put(setUpdatedAssessment(assessmentId, newAssessment));
  } catch (err) {
    yield put(setGlobalError(err));
    yield put(setAssessmentError(JSON.stringify(err)));
    console.error(err);
  }
}

function* fetchAssessment({ assessmentId }) {
  try {
    const assessment = yield Assessment.get(assessmentId);
    const signedUrl = yield Storage.get(assessment.video.path);
    if (assessment?.typeIsRams) {
      yield put(
        setRamsAssessment({
          ...assessment,
          signedUrl,
        }),
      );
    } else {
      yield put(
        setAssessment({
          ...assessment,
          signedUrl,
        }),
      );
    }
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* sendSuggestion({ assessmentId, suggestions }) {
  try {
    const assessment = yield Assessment.createSuggestion(assessmentId, {
      ...suggestions,
    });
    const signedUrl = yield Storage.get(assessment.video.path);
    yield put(setAssessment({ ...assessment, signedUrl }));
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* sendFeedback({
  assessmentId,
  fileId,
  assessmentVideo,
  isPositiveFeedback,
}) {
  try {
    const result = yield Assessment.updateAssessmentVideo(
      assessmentId,
      fileId,
      assessmentVideo,
    );
    yield put(setAssessmentSummarySuccess(result));
  } catch (err) {
    Logger.error(err);
    yield put(setGlobalError(err));
    yield put(setAssessmentSummaryError());
  }
}

function* requestNewVra({ assessmentId, newAssessment }) {
  try {
    yield Assessment.editAssessment(assessmentId, newAssessment);
    yield put(requestNewVraSuccess());
  } catch (err) {
    yield put(setGlobalError(err));
    yield put(requestNewVraError());
    console.error(err);
  }
}

function* generateAssessmentPdf({ assessmentId, eventProperties }) {
  const currentUserId = yield select(selectCurrentUserId);
  const isPdfModalDismissedByUsers = yield JSON.parse(
    window.localStorage.getItem('pdfInfoModalDismissed') || '{}',
  );
  const isDismissed = isPdfModalDismissedByUsers?.[currentUserId] ?? false;
  try {
    const objectId = yield Assessment.getPdfExportId(assessmentId);

    if (isDismissed) {
      yield put(setFileCreationToast(ExtensionType.PDF));
    } else {
      yield put(showInfoModal(ExtensionType.PDF));
    }

    setFileExportDataToLocalStorage(objectId, eventProperties);
    yield put(getPdfExportIdSuccess());
  } catch (err) {
    Logger.error(err);
    yield put(setGlobalError(err));
    yield put(getPdfExportIdError());
  }
}

export default function* assessmentsWatcher() {
  yield takeLatest(FETCH_ASSESSMENTS, fetchAssessments);
  yield takeLatest(UPDATE_ASSESSMENT, editAssessment);
  yield takeLatest(FETCH_ASSESSMENT, fetchAssessment);
  yield takeLatest(SEND_SUGGESTION, sendSuggestion);
  yield takeLatest(SEND_ASSESSMENT_SUMMARY_FEEDBACK, sendFeedback);
  yield takeLatest(REQUEST_NEW_VRA, requestNewVra);
  yield throttle(2000, GENERATE_ASSESSMENT_PDF, generateAssessmentPdf);
}
