import {
  LEGACY_VIEW,
  LIST_VIEW,
  LIST_VIEW_TAB,
  MAP_VIEW_TAB,
} from 'utils/constants';
import {
  APPEND_DOCUMENT,
  APPEND_EVIDENCE,
  APPEND_LEGACY_VIEW_JOBS,
  CLEAR_JOBS,
  FETCH_ACTIVE_JOB,
  FETCH_DOCUMENTS,
  FETCH_EVIDENCES,
  FETCH_LIST_JOBS,
  FETCH_JOB_PHOTOS,
  FETCH_LOGS,
  FETCH_LEGACY_VIEW_JOBS,
  FETCH_MORE_LEGACY_VIEW_JOBS,
  FETCH_PERMITS,
  FETCH_WEATHER_ERROR,
  FETCH_WEATHER_FOR_JOB,
  FETCH_WORK_ORDER_ITEMS,
  JOB_MARK_AS_READ,
  RESET_ACTIVE_JOB,
  SET_ACTIVE_JOB,
  SET_DOCUMENTS,
  SET_EVIDENCES,
  SET_LIST_JOBS,
  SET_LEGACY_VIEW_JOBS,
  SET_JOB_FILTERS,
  SET_JOB_PHOTOS,
  SET_JOB_SORT,
  SET_JOB_STATS,
  SET_LOGS,
  SET_PERMITS,
  SET_WEATHER_FOR_JOB,
  SET_WORK_ORDER_ITEMS,
  UPDATE_JOB,
  UPDATE_PERMIT,
  UPDATE_USER_REVIEW_PSI_SCORE,
  SET_LEGACY_VIEW_JOB_STATS,
  SET_JOB_BLOCKERS,
  FETCH_JOB_BLOCKERS,
  FETCH_DOCUMENTS_ERROR,
  FETCH_LOGS_ERROR,
  FETCH_JOB_BLOCKERS_ERROR,
  FETCH_WORK_ORDER_ITEMS_ERROR,
  FETCH_PERMITS_ERROR,
  FETCH_EVIDENCES_ERROR,
  SET_PINNED_JOB_STATUS,
  FETCH_MAP_JOBS,
  SET_MAP_JOBS,
  SET_ACTIVE_JOB_NEARBY_PATH,
  ADD_ME_TO_JOB,
  ADD_ME_TO_JOB_SUCCESS,
  ADD_ME_TO_JOB_ERROR,
  FETCH_JOB_STATS,
  FETCH_JOB_STATS_THROTTLED,
  SET_PREVIOUS_TIMELINE_PATH,
  PROCESS_UNREAD_MESSAGE,
  GENERATE_JOB_ZIP,
  GENERATE_JOB_ZIP_SUCCESS,
  GENERATE_JOB_ZIP_ERROR,
} from './actionTypes';

export const fetchJobs = (filters, source, page, isSwitchingTabsOnly) => {
  switch (source) {
    case LIST_VIEW:
    case LIST_VIEW_TAB:
      return {
        type: FETCH_LIST_JOBS,
        filters,
        page,
        requestJobStats: !isSwitchingTabsOnly,
      };
    case MAP_VIEW_TAB: {
      return {
        type: FETCH_MAP_JOBS,
        filters,
        requestJobStats: !isSwitchingTabsOnly,
      };
    }
    case LEGACY_VIEW:
      return {
        type: FETCH_LEGACY_VIEW_JOBS,
        filters,
        page,
      };
    default:
      return;
  }
};

export const fetchJobStats = () => {
  return { type: FETCH_JOB_STATS };
};

export const fetchJobStatsThrottled = (workspaceId, userIds) => {
  return { type: FETCH_JOB_STATS_THROTTLED, workspaceId, userIds };
};

export const clearJobs = () => ({
  type: CLEAR_JOBS,
});

export const fetchMoreLegacyViewJobs = (url) => ({
  type: FETCH_MORE_LEGACY_VIEW_JOBS,
  url,
});

export const fetchActiveJob = (jobId, options) => ({
  type: FETCH_ACTIVE_JOB,
  jobId,
  options,
});

export const setListJobs = (jobs, count, page) => ({
  type: SET_LIST_JOBS,
  jobs,
  count,
  page,
});

export const setMapJobs = (jobs) => ({
  type: SET_MAP_JOBS,
  jobs,
});

export const setJobPinnedStatus = (jobId, status) => ({
  type: SET_PINNED_JOB_STATUS,
  jobId,
  status,
});

export const setLegacyViewJobs = (jobs, nextLink, count, page) => ({
  type: SET_LEGACY_VIEW_JOBS,
  jobs,
  nextLink,
  count,
  page,
});

export const appendLegacyViewJobs = (jobs, nextLink) => ({
  type: APPEND_LEGACY_VIEW_JOBS,
  jobs,
  nextLink,
});

export const setActiveJob = (activeJob, updateAllJobs = true) => ({
  type: SET_ACTIVE_JOB,
  activeJob,
  updateAllJobs,
});

export const setActiveJobNearbyPath = (activeJobId, nearbyJobPath) => ({
  type: SET_ACTIVE_JOB_NEARBY_PATH,
  activeJobId,
  nearbyJobPath,
});

export const setPreviousTimelinePath = (activeJobId, previousTimelinePath) => ({
  type: SET_PREVIOUS_TIMELINE_PATH,
  activeJobId,
  previousTimelinePath,
});

export const resetActiveJob = () => ({ type: RESET_ACTIVE_JOB });

export const updateJob = (jobId) => ({ type: UPDATE_JOB, jobId });

export const setJobFilters = (filters, newView) => ({
  type: SET_JOB_FILTERS,
  filters,
  newView,
});

export const setJobSort = (sort) => ({
  type: SET_JOB_SORT,
  sort,
});

export const markJobAsRead = (jobId) => ({
  type: JOB_MARK_AS_READ,
  jobId,
});

export const setJobStats = (jobStats) => ({
  type: SET_JOB_STATS,
  jobStats,
});

export const setLegacyViewJobStats = (jobStats) => ({
  type: SET_LEGACY_VIEW_JOB_STATS,
  jobStats,
});

export const fetchWeatherForJob = (jobId) => ({
  type: FETCH_WEATHER_FOR_JOB,
  jobId: +jobId,
});

export const setWeatherForJob = (weather) => ({
  type: SET_WEATHER_FOR_JOB,
  weather,
});

export const fetchWeatherError = () => ({
  type: FETCH_WEATHER_ERROR,
});

export const setEvidences = (evidences) => ({
  type: SET_EVIDENCES,
  evidences,
});

export const fetchEvidences = (jobId) => ({
  type: FETCH_EVIDENCES,
  jobId,
});

export const appendEvidence = (evidence) => ({
  type: APPEND_EVIDENCE,
  evidence,
});

export const fetchEvidencesError = () => ({
  type: FETCH_EVIDENCES_ERROR,
});

export const setDocuments = (jobId, documents) => ({
  type: SET_DOCUMENTS,
  jobId,
  documents,
});

export const fetchDocuments = (jobId) => ({
  type: FETCH_DOCUMENTS,
  jobId,
});

export const fetchDocumentsError = () => ({
  type: FETCH_DOCUMENTS_ERROR,
});

export const appendDocument = (jobId, document) => ({
  type: APPEND_DOCUMENT,
  jobId,
  document,
});

export const setLogs = (jobId, logs) => ({
  type: SET_LOGS,
  jobId,
  logs,
});

export const fetchLogs = (jobId) => ({
  type: FETCH_LOGS,
  jobId,
});

export const fetchLogsError = () => ({
  type: FETCH_LOGS_ERROR,
});

export const setJobBlockers = (jobId, blockers) => ({
  type: SET_JOB_BLOCKERS,
  jobId,
  blockers,
});

export const fetchJobBlockers = (jobId) => ({
  type: FETCH_JOB_BLOCKERS,
  jobId,
});

export const fetchJobBlockersError = () => ({
  type: FETCH_JOB_BLOCKERS_ERROR,
});

export const setWorkOrderItems = (jobId, workOrderItems) => ({
  type: SET_WORK_ORDER_ITEMS,
  jobId,
  workOrderItems,
});

export const fetchWorkOrderItems = (jobId) => ({
  type: FETCH_WORK_ORDER_ITEMS,
  jobId,
});
export const fetchWorkOrderItemsError = () => ({
  type: FETCH_WORK_ORDER_ITEMS_ERROR,
});

export const setPermits = (jobId, permits) => ({
  type: SET_PERMITS,
  jobId,
  permits,
});

export const updatePermit = (jobId, permit) => ({
  type: UPDATE_PERMIT,
  jobId,
  permit,
});

export const fetchPermits = (jobId) => ({
  type: FETCH_PERMITS,
  jobId,
});

export const fetchPermitsError = () => ({
  type: FETCH_PERMITS_ERROR,
});

export const setJobPhotos = (photos) => ({
  type: SET_JOB_PHOTOS,
  photos,
});

export const fetchPhotosForJob = (jobId) => ({
  type: FETCH_JOB_PHOTOS,
  jobId,
});

export const updateUserReviewPsiScore = (predictionId) => ({
  type: UPDATE_USER_REVIEW_PSI_SCORE,
  predictionId,
});

export const addMeToJob = () => ({
  type: ADD_ME_TO_JOB,
});

export const addMeToJobSuccess = (userId) => ({
  type: ADD_ME_TO_JOB_SUCCESS,
  userId,
});

export const addMeToJobError = () => ({
  type: ADD_ME_TO_JOB_ERROR,
});

export const processUnreadMessage = (jobId, messageId) => ({
  type: PROCESS_UNREAD_MESSAGE,
  jobId,
  messageId,
});

export const getZipExportId = (jobId, eventProperties) => ({
  type: GENERATE_JOB_ZIP,
  jobId,
  eventProperties,
});

export const getZipExportIdSuccess = () => ({
  type: GENERATE_JOB_ZIP_SUCCESS,
});

export const getZipExportIdError = () => ({
  type: GENERATE_JOB_ZIP_ERROR,
});
