import theme from 'theme';
import { Loader, Navbar, Tabs as MobileTabs } from 'components/ui';
import { useIsMobile, useSafetyPrediction, useText } from 'hooks';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { fetchActiveJob } from 'store/actions/jobs';
import { clearMessages } from 'store/actions/messages';
import {
  selectIsStreamline,
  selectWorkspaceHasSettingEnabled,
  selectWorkspaceSettings,
} from 'store/selectors';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import RootState from 'model/State/RootState';

import { Container } from './styled';
import { useStatusesAndDeletedStatus } from './hooks';
import { MobileView } from './components/MobileView';
import { DesktopView } from './components/DesktopView';
import { ExportInfoModal } from '../FileExport/ExportInfoModal';

export const { colors } = theme;

// Overview
export default () => {
  const dispatch = useDispatch();
  const [showDeleteJob, setShowDeleteJob] = useState<boolean>(false);
  const navigate = useNavigate();
  const { jobId } = useParams();
  const location = useLocation();
  const isMobile = useIsMobile();
  const getText = useText();
  const { isFeedbackNeeded } = useSafetyPrediction();
  const canUseListView = useSelector(
    selectWorkspaceHasSettingEnabled('enable_jobs_page_v2'),
  );

  const { isManager, isBackOffice } = useSelector(
    (state: RootState) => state.session,
  );
  const { activeJob, isFetchingActiveJob } = useSelector(
    (state: RootState) => state.jobs,
  );
  const { permits_enabled } = useSelector(selectWorkspaceSettings);
  const isStreamline = useSelector(selectIsStreamline);

  const { statuses } = useStatusesAndDeletedStatus();

  const JobViewComponent = isMobile ? MobileView : DesktopView;

  useEffect(() => {
    if (!activeJob || activeJob.id !== parseInt(jobId ?? '', 10)) {
      dispatch(fetchActiveJob(jobId));
      dispatch(clearMessages());
    }
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isFetchingActiveJob && activeJob) {
      Analytics.trackPage(constants.EVENT_PAGE_VIEW_JOB_OVERVIEW, {
        jobId: activeJob.id,
      });

      if (
        isFeedbackNeeded(
          activeJob.user_review_psi_score_id,
          activeJob.safety_prediction_score,
        ) &&
        jobId &&
        location?.pathname?.endsWith(jobId)
      ) {
        navigate(`/jobs/${activeJob.id}/safety-review`);
      }
    }
  }, [activeJob]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Navbar
        title={activeJob?.job_number}
        defaultBackPath='/jobs'
        mobileOnly
      />
      <Container item xs={24} sm={10} md={12} lg={12}>
        <MobileTabs.Mobile
          left={{
            text: getText('tabs_timeline'),
            to: `/jobs/${jobId}/timeline`,
          }}
          right={{
            text: getText('tabs_overview'),
            to: `/jobs/${jobId}`,
            isActive: true,
          }}
        />
        {!activeJob && <Loader />}
        {activeJob && (
          <JobViewComponent
            activeJob={activeJob}
            statuses={statuses}
            canUseListView={canUseListView}
            jobId={jobId}
            showDeleteJob={showDeleteJob}
            setShowDeleteJob={setShowDeleteJob}
            isStreamline={isStreamline}
            permitsEnabled={permits_enabled}
            isManager={isManager}
            isBackOffice={isBackOffice}
          />
        )}
        <ExportInfoModal />
      </Container>
    </>
  );
};
