export enum DownloadType {
  ASSESSMENT = 'assessment',
  FORM = 'form',
  PERMIT = 'permit',
  JOB = 'job',
}

export enum DownloadSource {
  JOB_OVERVIEW = 'job-overview',
  MODAL = 'modal',
  INSIGHTS = 'insights',
}

export const EMAIL_SOURCE = 'email';

export const EVENT_DOWNLOAD_CLICKED = 'download-clicked';
export const EVENT_DOWNLOAD_TOAST_POPUP = 'download-toast-pop-up';
export const EVENT_DOWNLOAD_TOAST_POPUP_LINK_CLICKED =
  'download-toast-pop-up-link-clicked';
