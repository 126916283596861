import { useCallback, useEffect, useState } from 'react';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { Loader } from 'components/ui';
import { useText } from 'hooks';
import CustomForm from 'model/Forms/CustomForm';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { SortOrder } from 'model/enum/SortOrder';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  fetchJobForm,
  fetchPermitForm,
  fetchUserForm,
} from 'store/actions/insights';
import {
  selectActiveWorkspaceUuid,
  selectIsLoadingJobForm,
  selectIsLoadingPermitForm,
  selectIsLoadingUserForm,
  selectJobFormData,
  selectPermitFormData,
  selectUserFormData,
} from 'store/selectors';
import { Pagination } from 'components/ui/Display';
import { downloadBlob } from 'helpers/files';
import * as Logger from 'utils/logger';
import { setGlobalError } from 'store/slices/notifications';
import {
  formatDateAndTime,
  formatDateForDataExport,
  isIsoDate,
} from 'helpers/dates';
import RootState from 'model/State/RootState';
import { CustomFormClass } from 'model/Form';
import { Insights } from 'api';
import { formatLicensePlate } from 'helpers/names';
import {
  CommentsCross,
  CommentsTick,
  DataContainer,
  DataRow,
  EyeIcon,
  FormIconWrapper,
  H2,
  HeaderWrapper,
  PointsSum,
  StyledDataGrid,
  ViewButton,
  WarningIcon,
} from './styled';
import {
  FIRST_COLUMN_KEY,
  FORM,
  HAS_COMMENTS,
  IS_BREACH,
  JOB_NUMBER,
  LAST_COLUMN_KEY,
  LAST_COLUMN_VALUE,
  LAST_COLUMN_VALUE_FOR_HAVS,
  POINTS_SUM,
  SECOND_COLUMN_KEY,
  THIRD_COLUMN_KEY,
  VEHICLE_CHECK_REGISTRATION,
  isLastColumn,
} from './constants';
import { ExportButton } from '../../FileExport/ExportButton';
import { DownloadSource, DownloadType } from '../../FileExport/constants';
import { ExportExcelButton } from '../DataExport/ExportExcelButton';

type Props = {
  form: CustomForm;
  formClass: CustomFormClass;
  depotId: number;
  search: string;
  startDate: Date;
  endDate: Date;
  selectedMemberIds?: number[];
  isWeekendIncluded: boolean;
  isHavsForm: boolean;
};

// InsightCustomForm
const InsightCustomForm = ({
  form,
  formClass,
  depotId,
  search,
  startDate,
  endDate,
  selectedMemberIds = [],
  isWeekendIncluded,
  isHavsForm,
}: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useSelector((state: RootState) => state.session);
  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);
  const [currentPage, setCurrentPage] = useState<number>(
    location.state?.page ?? 1,
  );
  const memberIds = selectedMemberIds?.join() ?? '';
  const [isGeneratingExport, setIsGeneratingExport] = useState<boolean>(false);

  const {
    results,
    columns,
    count: total,
  } = useSelector(
    formClass === CustomFormClass.USER
      ? selectUserFormData(form.id)
      : formClass === CustomFormClass.JOB
        ? selectJobFormData(form.id)
        : selectPermitFormData(form.id),
  );
  const isLoading = useSelector(
    formClass === CustomFormClass.USER
      ? selectIsLoadingUserForm(form?.id)
      : formClass === CustomFormClass.JOB
        ? selectIsLoadingJobForm(form?.id)
        : selectIsLoadingPermitForm(form?.id),
  );

  const count = Math.ceil(total / constants.INSIGHTS_TABLE_PAGE_SIZE);

  useEffect(() => {
    if (form?.id) {
      Analytics.trackEvent(constants.FORM_DASHBOARD_OPENED, {
        form_name: form?.title,
        form_type_id: form?.id,
        formtype: formClass,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getFormData = useCallback(
    (page: number = 1) => {
      if (depotId) {
        switch (formClass) {
          case CustomFormClass.USER:
            dispatch(
              fetchUserForm(
                form.id,
                depotId,
                startDate,
                endDate,
                !isWeekendIncluded,
                memberIds,
                page,
                selectedWorkspaceUuid,
                search,
                isHavsForm,
              ),
            );
            break;
          case CustomFormClass.JOB:
            dispatch(
              fetchJobForm(
                form.id,
                depotId,
                startDate,
                endDate,
                !isWeekendIncluded,
                memberIds,
                page,
                selectedWorkspaceUuid,
                search,
              ),
            );
            break;
          case CustomFormClass.PERMIT:
            dispatch(
              fetchPermitForm(
                form.id,
                depotId,
                startDate,
                endDate,
                !isWeekendIncluded,
                memberIds,
                page,
                selectedWorkspaceUuid,
                search,
              ),
            );
            break;
        }
      }
      setCurrentPage(page);
    },
    [
      dispatch,
      depotId,
      startDate,
      endDate,
      form.id,
      isHavsForm,
      isWeekendIncluded,
      memberIds,
      search,
      selectedWorkspaceUuid,
      formClass,
    ],
  );

  useEffect(() => {
    getFormData();
  }, [getFormData]);

  const onPageChange = (page: number) => {
    getFormData(page);
  };

  const orderedColumns = columns
    ? [
        [FIRST_COLUMN_KEY, columns[FIRST_COLUMN_KEY]],
        [SECOND_COLUMN_KEY, columns[SECOND_COLUMN_KEY]],
        [THIRD_COLUMN_KEY, columns[THIRD_COLUMN_KEY]],
        ...Object.entries(columns),
        [
          LAST_COLUMN_KEY,
          isHavsForm ? LAST_COLUMN_VALUE_FOR_HAVS : LAST_COLUMN_VALUE,
        ],
      ]
    : null;

  const getColumnDefinition = ([key, columnHeaderLabel]: any[]): GridColDef => {
    let renderCell = (props: GridRenderCellParams) => {
      if (isIsoDate(props.value)) {
        return <span>{formatDateAndTime(props.value)}</span>;
      } else {
        return <span>{props.value}</span>;
      }
    };

    switch (key) {
      case POINTS_SUM:
        renderCell = (props: GridRenderCellParams) => {
          const isBreach = !!props.row?.[IS_BREACH];
          return (
            <>
              {isBreach && <WarningIcon />}
              <PointsSum $isRed={isBreach}>{props.row?.[POINTS_SUM]}</PointsSum>
            </>
          );
        };
        break;

      case VEHICLE_CHECK_REGISTRATION:
        renderCell = (props: GridRenderCellParams) => (
          <span>
            {formatLicensePlate(props.row?.[VEHICLE_CHECK_REGISTRATION])}
          </span>
        );
        break;

      case FORM:
        renderCell = (props: GridRenderCellParams) => {
          const isBreach = props.row?.[IS_BREACH];
          const hasComments = props.row?.[HAS_COMMENTS];

          return (
            <FormIconWrapper>
              <ViewButton
                onClick={() =>
                  navigate(
                    `/jobs/insights/forms/${props.row?.id}?form=${formClass}`,
                    {
                      state: {
                        formTypeId: form.id,
                        formClass,
                        depotId,
                        startDate,
                        endDate,
                        isWeekendIncluded,
                        memberIds,
                        page: currentPage,
                        selectedWorkspaceUuid,
                        search,
                      },
                    },
                  )
                }
                isText={isHavsForm}
              >
                {isHavsForm ? (
                  <>
                    {getText('insights_forms_havs_view_and_comment')}
                    {isBreach && (
                      <>{hasComments ? <CommentsTick /> : <CommentsCross />}</>
                    )}
                  </>
                ) : (
                  <EyeIcon />
                )}
              </ViewButton>
              <ExportButton
                type={DownloadType.FORM}
                itemId={props.row?.id}
                jobId={props.row?.job_id}
                source={DownloadSource.INSIGHTS}
                backgroundColor={theme.colors.lightGrey}
                formClass={formClass}
              />
            </FormIconWrapper>
          );
        };
        break;
    }

    return {
      field: key,
      headerName: columnHeaderLabel,
      sortable: false,
      renderCell,
      ...(isLastColumn(key) && { headerClassName: 'last-column' }),
    };
  };

  const tableColumns = orderedColumns
    ? orderedColumns
        .filter((tuple, index) => {
          const firstIndex = orderedColumns.findIndex(
            (column) => column[0] === tuple[0],
          );
          // only keep columns that have a header value and are unique
          return tuple[1] !== undefined && firstIndex === index;
        })
        .map(getColumnDefinition)
    : null;

  const onDownloadDataClick = async () => {
    try {
      setIsGeneratingExport(true);
      const exportFunction = isHavsForm
        ? Insights.getFormDataExportForCustomHavsForm
        : Insights.getFormDataExport;

      const blob = await exportFunction(
        formClass,
        form?.id,
        depotId,
        startDate,
        endDate,
        selectedWorkspaceUuid,
        !isWeekendIncluded,
        memberIds,
        search,
      );

      const filename = `${form?.title} ${formatDateForDataExport(
        startDate,
      )} - ${formatDateForDataExport(endDate)}.xlsx`;
      downloadBlob(blob, filename);
    } catch (err) {
      Logger.error(err);
      dispatch(setGlobalError(err));
    }
    setIsGeneratingExport(false);
  };

  return (
    <DataContainer>
      <HeaderWrapper>
        <H2>{form?.title}</H2>
        {form?.allow_export && (
          <ExportExcelButton
            onClick={onDownloadDataClick}
            disabled={isGeneratingExport || isLoading || total === 0}
            loading={isGeneratingExport}
            event={constants.EVENT_USER_FORM_DATA_EXPORT}
            eventProperties={{
              formId: form?.id,
              formTitle: form?.title,
              userId: currentUser?.id,
              depotId,
              search,
              startDate,
              endDate,
              memberIds: memberIds,
              isWeekendIncluded,
            }}
          />
        )}
      </HeaderWrapper>
      {results ? (
        <DataRow>
          <StyledDataGrid
            rows={results}
            columns={tableColumns}
            isEmpty={!results?.length}
            width={`${100 / (tableColumns?.length ?? 1)}%`}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableExtendRowFullWidth
            disableRowSelectionOnClick
            rowsPerPageOptions={[]}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: JOB_NUMBER,
                    sort: SortOrder.ASCENDING,
                  },
                ],
              },
            }}
            disableColumnResize
            loading={isLoading}
            slots={{
              loadingOverlay: () => <Loader />,
              footer: () => {
                return (
                  count > 1 && (
                    <Pagination
                      count={count}
                      page={currentPage}
                      onChange={onPageChange}
                      extraStyles={isLoading ? { visibility: 'hidden' } : {}}
                    />
                  )
                );
              },
            }}
          />
        </DataRow>
      ) : (
        <DataRow>
          <Loader />
        </DataRow>
      )}
    </DataContainer>
  );
};

export default InsightCustomForm;
