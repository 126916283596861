import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { Bar } from 'react-chartjs-2';
import { Loader } from 'components/ui';
import theme from 'theme';
import { useSelector } from 'react-redux';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { useIsMounted, useText } from 'hooks';
import { getJobBlockerCombinedTitle } from 'helpers/names';
import { getJobBlockerData, getSelectedDetails } from './TooltipProvider';
import {
  ActiveElement,
  CategoryScale,
  Chart,
  ChartData,
  ChartOptions,
  Plugin,
  PointElement,
  BarElement,
  Legend,
  Tooltip,
} from 'chart.js';
import {
  InsightJobBlockerStat,
  LogDetail,
} from 'model/Insight/InsightJobBlockerStat';
import TableData from 'model/Insight/TableData';
import { formatDate, minutesToHours } from 'helpers/dates';

Chart.register(CategoryScale, PointElement, BarElement, Legend, Tooltip);

const { colors, fonts } = theme;

// Styled Components
const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
`;

const GraphWrapper = styled.div`
  width: 100%;
  background-color: ${colors.white};
  border-top: 3px solid ${colors.black};
`;

const GraphContainer = styled.div`
  margin: 16px;
  min-height: 475px;
`;

const StyledBar = styled(Bar)`
  background-color: ${colors.white};
`;

type Props = {
  graphData: InsightJobBlockerStat[];
  startDate: Date;
  endDate: Date;
  setTableData?: Dispatch<SetStateAction<TableData | null>>;
};

type GraphDataset = {
  label: string;
  data: number[] | number;
  borderColor?: string;
  borderWidth?: number;
  backgroundColor?: string[];
  pointRadius?: number;
  pointHoverRadius?: number;
  borderDash?: [number, number];
  type?: string;
  fill?: boolean;
  barPercentage?: number;
  categoryPercentage?: number;
  jobDetails?: LogDetail[][];
};

// InsightJobBlockerGraph
const InsightJobBlockerGraph = ({
  graphData,
  startDate,
  endDate,
  setTableData,
}: Props): JSX.Element => {
  const getText = useText();
  const [graphDataSet, setGraphDataSet] = useState<{
    labels: string[];
    datasets: GraphDataset[];
  }>({
    labels: [],
    datasets: [],
  });
  const [selectedItem, setSelectedItem] = useState<ActiveElement>();
  const isMounted = useIsMounted();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  const generateGraphData = (results: InsightJobBlockerStat[]) => {
    const data: { labels: string[]; datasets: GraphDataset[] } = {
      labels: [],
      datasets: [],
    };

    data.labels = results.map((result) =>
      getJobBlockerCombinedTitle(result, globalAppLanguage),
    );
    data.datasets.push({
      label: getText('insights_graph_preceding_period'),
      data: results.map((result) =>
        minutesToHours(result.timeLostPreviousPeriod),
      ),
      backgroundColor: results.map(() => colors.grey),
      jobDetails: results.map((result) => result.logDetails),
    });
    data.datasets.push({
      label: `${formatDate(startDate)} - ${formatDate(endDate)}`,
      data: results.map((result) =>
        minutesToHours(result.timeLostCurrentPeriod),
      ),
      backgroundColor: results.map(() => colors.orange),
      jobDetails: results.map((result) => result.logDetails),
    });

    if (isMounted.current) {
      setGraphDataSet(data);
    }
  };

  useEffect(() => {
    if (graphData) {
      setGraphDataSet({
        labels: [],
        datasets: [],
      });
      generateGraphData(graphData);
    }
  }, [graphData, globalAppLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  const options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    aspectRatio: 2,
    onClick: (_, item, chart) => {
      // Make sure if dataset already selected, don't do anything further
      if (
        item.length &&
        item[0] !== selectedItem &&
        (item[0].datasetIndex !== selectedItem?.datasetIndex ||
          item[0].index !== selectedItem?.index)
      ) {
        if (setTableData) {
          setTableData(getJobBlockerData(chart));
        }
        const selectedDetails = getSelectedDetails(chart);
        const modifiedDatasets = graphDataSet?.datasets;

        if (
          selectedItem &&
          modifiedDatasets &&
          modifiedDatasets?.[selectedItem.datasetIndex]?.backgroundColor?.[
            selectedItem.index
          ]
        ) {
          modifiedDatasets[selectedItem.datasetIndex].backgroundColor![
            selectedItem.index
          ] = selectedItem.datasetIndex === 0 ? colors.grey : colors.orange;
        }

        setSelectedItem(item[0]);
        if (
          modifiedDatasets?.[selectedDetails.datasetIndex]?.backgroundColor?.[
            selectedDetails.dataIndex
          ]
        )
          modifiedDatasets[selectedDetails.datasetIndex].backgroundColor![
            selectedDetails.dataIndex
          ] = colors.blue;
        setGraphDataSet((prevState) => ({
          ...prevState,
          datasets: modifiedDatasets ?? [],
        }));
        chart.update();
        Analytics.trackEvent(constants.INSIGHTS_JOB_BLOCKERS_DRILLDOWN, {
          jobBlocker: graphDataSet?.labels[selectedDetails.dataIndex],
          period: graphDataSet?.datasets[selectedDetails.datasetIndex].label,
        });
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'end',
        labels: {
          font: {
            family: fonts.bold,
          },
          color: colors.darkGrey,
          padding: 16,
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
            family: fonts.default,
          },
          autoSkip: false,
          backdropPadding: {
            top: 16,
          },
          callback: (value: any) => {
            let label = graphDataSet.labels[value];
            if (label && label.length > 75) {
              label = label.substring(0, 75);
              label += '..';
            }
            return label ?? '';
          },
        },
      },
      x: {
        stacked: false,
        ticks: {
          font: {
            size: 14,
          },
        },
      },
    },
  };

  const plugin: Plugin<'bar', Object>[] = [
    {
      id: 'plugin',
      beforeDraw: (chart) => {
        const precedingLegend = chart?.legend?.legendItems?.[0];
        const lastXLegend = chart?.legend?.legendItems?.[1];
        if (precedingLegend) {
          precedingLegend.fillStyle = colors.grey;
        }
        if (lastXLegend) {
          lastXLegend.fillStyle = colors.orange;
        }
      },
    },
  ];

  return (
    <Container>
      <GraphWrapper>
        <GraphContainer>
          {graphDataSet ? (
            <StyledBar
              data={graphDataSet as ChartData<'bar'>}
              options={options}
              height={475}
              plugins={plugin}
            />
          ) : (
            <Loader />
          )}
        </GraphContainer>
      </GraphWrapper>
    </Container>
  );
};

export default InsightJobBlockerGraph;
