import { useEffect, useRef, useState } from 'react';
import { useText } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalLanguageSetting, selectJobsView } from 'store/selectors';
import { RatingSteps } from './RatingSteps';
import RootState from 'model/State/RootState';
import { HazardCount, HazardTitle, TitleContainer } from './styled';
import { Hazard } from 'model/Assessment/Hazard';
import { Button } from 'components/ui';
import {
  EVENT_RAMS_SUMMARY_DONE_BUTTON_CLICKED,
  EVENT_RAMS_SUMMARY_NEXT_BUTTON_CLICKED,
} from '../constants';
import { EVENT_PROPERTIES_PAGE_SOURCE_FIELD } from 'utils/constants';
import {
  selectActiveRamsAssessment,
  setAssessmentStage,
  setHazardRatings,
} from 'store/slices/ramsAssessments';
import {
  ActionButtons,
  ActionButtonsWrapper,
  HazardStepContentContainer,
} from '../styled';
import { HazardsTag } from '../HazardsTag';
import {
  RamsAssessments,
  RamsRating,
  RamsStages,
  RatingValue,
} from 'model/Assessment/RAMS';
import { getLocalizedCamelCaseValue } from 'helpers/locales';

// HazardRating
export const HazardRating = (): JSX.Element | null => {
  const getText = useText();
  const dispatch = useDispatch();
  const { selectedHazards, selectedControls, ratings, editedHazard } =
    useSelector((state: RootState) => state.ramsAssessments);
  const assessment = useSelector(selectActiveRamsAssessment);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const view = useSelector(selectJobsView);
  const notRatedHazard = selectedHazards.find(
    (hazard) => !ratings.some((rating) => rating.hazardId === hazard.id),
  );
  const isEditing = !!editedHazard;
  const hazardToRate: Hazard | null =
    (!!editedHazard ? editedHazard : notRatedHazard) ?? null;
  const [rating, setRating] = useState<RamsRating | null>(null);
  const showDoneButton =
    isEditing ||
    selectedHazards.length === 1 ||
    (hazardToRate &&
      selectedHazards.indexOf(hazardToRate) + 1 === selectedHazards.length);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!notRatedHazard && !editedHazard) {
      dispatch(setAssessmentStage(RamsStages.hazardRatingReview));
    }
  }, [notRatedHazard, editedHazard]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveHazardRating = () => {
    if (rating) {
      RamsAssessments.scrollToRef(containerRef);
      const { preControlRating, postControlRating, hazardId } = rating;
      dispatch(
        setHazardRatings({
          preControlRating,
          selectedControls,
          postControlRating,
          hazardId,
        }),
      );
    }
  };

  const onRatingChange = (
    preControlRating: RatingValue,
    postControlRating: RatingValue,
    hazardId: number,
  ) => {
    setRating({
      preControlRating,
      postControlRating,
      hazardId,
    });
  };

  const isSaveDisabled: boolean =
    !assessment?.editable ||
    (!!rating &&
      (rating.preControlRating?.severity === 0 ||
        rating.preControlRating?.likelihood === 0));

  if (hazardToRate) {
    return (
      <>
        <HazardStepContentContainer ref={containerRef}>
          <HazardsTag text={getText('rams_summary_hazard_added')} />
          <TitleContainer>
            <HazardTitle>
              {getLocalizedCamelCaseValue(
                hazardToRate,
                'title',
                globalAppLanguage,
              )}
            </HazardTitle>
            {!editedHazard && (
              <HazardCount>
                {`(${getText('rams_rating_hazard_of_total', {
                  index: selectedHazards.indexOf(hazardToRate) + 1,
                  total: selectedHazards.length,
                })})`}
              </HazardCount>
            )}
          </TitleContainer>
          <RatingSteps
            onRatingChange={onRatingChange}
            hazardId={hazardToRate.id}
          />
        </HazardStepContentContainer>
        <ActionButtonsWrapper>
          <ActionButtons>
            <Button.Primary
              onClick={saveHazardRating}
              text={getText(
                showDoneButton
                  ? 'rams_summary_done_button'
                  : 'rams_summary_next_hazard_button',
              )}
              disabled={isSaveDisabled}
              event={
                showDoneButton
                  ? EVENT_RAMS_SUMMARY_DONE_BUTTON_CLICKED
                  : EVENT_RAMS_SUMMARY_NEXT_BUTTON_CLICKED
              }
              eventProperties={{
                [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
              }}
              extraStyles={{
                margin: 0,
                alignSelf: 'flex-end',
                height: 'inherit',
                maxHeight: '55px',
              }}
            />
          </ActionButtons>
        </ActionButtonsWrapper>
      </>
    );
  }

  return null;
};
