import { useMemo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useText } from 'hooks';
import { Notification, ToastType } from './constants';
import { ToastBase } from './ToastBase';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetFileCreation,
  selectFileCreationNotifications,
} from 'store/slices/notifications';
import { CreatePdfIcon, DownloadZipIcon } from './styled';
import { FileCreationItem } from 'model/State/notifications/FileCreationItem';
import { ExtensionType } from 'model/FileExport';

export const FileCreationToast = (): JSX.Element => {
  const notifications = useSelector(selectFileCreationNotifications);
  const getText = useText();
  const dispatch = useDispatch();
  const CONTAINER_ID = 'pdf-creation-container';
  const notificationList: Notification[] = useMemo(
    () =>
      notifications.map((item: FileCreationItem) => {
        const isPdf: boolean = item.type === ExtensionType.PDF;
        return {
          id: item.id,
          title: isPdf
            ? getText('pdf_export_info_modal_title')
            : getText('create_zip_file_toast_message'),
          customIcon: isPdf ? CreatePdfIcon : DownloadZipIcon,
          canBeDismissed: false,
        };
      }),
    [notifications, getText],
  );

  const onClose = (id: number) => {
    dispatch(resetFileCreation(id));
  };

  return (
    <ToastBase
      containerId={CONTAINER_ID}
      type={ToastType.SECONDARY}
      notifications={notificationList}
      onClose={(id) => onClose(id)}
      autoClose={5000}
    />
  );
};
