import { ExtensionType } from 'model/FileExport';

export const downloadBlob = async (blob: Blob, filename: string) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;

  document.body.appendChild(link);

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  document.body.removeChild(link);
};

export const getFileNameFromResponse = (
  responseUrl: string,
  fileId: string,
  extensions: ExtensionType[],
): string => {
  const fileNameExpression = `${fileId}/.*.(${extensions.join('|')})`;
  const fileNameWithId =
    RegExp(fileNameExpression).exec(responseUrl)?.[0] ?? '';
  const fileName = fileNameWithId.replace(`${fileId}/`, '');
  return decodeURIComponent(fileName);
};

export const setFileExportDataToLocalStorage = (
  objectId: string,
  properties: object,
): void => {
  const fileDownloadsStorageObject: string | null =
    window.localStorage.getItem('fileDownloads');
  const fileDownloads = JSON.parse(
    fileDownloadsStorageObject?.length ? fileDownloadsStorageObject : '{}',
  );
  window.localStorage.setItem(
    'fileDownloads',
    JSON.stringify({ ...fileDownloads, [objectId]: properties }),
  );
};

export const getFileExportDataFromLocalStorage = (
  objectId: string,
): object | undefined => {
  const fileDownloadsStorageObject: string | null =
    window.localStorage.getItem('fileDownloads');
  const fileDownloads = JSON.parse(
    fileDownloadsStorageObject?.length ? fileDownloadsStorageObject : '{}',
  );
  return fileDownloads[objectId];
};

export const removeFileExportDataFromLocalStorage = (
  objectId: string,
): void => {
  const fileDownloadsStorageObject: string | null =
    window.localStorage.getItem('fileDownloads');
  const fileDownloads = JSON.parse(
    fileDownloadsStorageObject?.length ? fileDownloadsStorageObject : '{}',
  );
  const { [objectId]: propToRemove, ...remainingProps } = fileDownloads;
  window.localStorage.setItem('fileDownloads', JSON.stringify(remainingProps));
};
