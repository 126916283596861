import { Button } from '@mui/material';
import {
  DownloadNoBackground,
  DownloadZipFile,
  RequestDocuments,
} from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media, mixins } = theme;

export const Footer = styled.div`
  height: 50px;
  background-color: ${colors.black};
  display: flex;
  align-items: center;
  padding: 8px 0;
  ${media.xxl`
    height: 40px;
  `}
`;

export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-left: 5px;
`;

export const DownloadButton = styled(({ backgroundColor, large, ...rest }) => (
  <Button {...rest} />
))`
  &.MuiButtonBase-root {
    height: 100%;
    max-width: ${({ large }) => (large ? 65 : 40)}px;
    min-width: 0;
    border-radius: 0;
    margin-left: 5px;
    &:hover {
      background-color: ${({ backgroundColor }) => backgroundColor};
    }
    &:active {
      background-color: ${colors.orange};
    }
    &.Mui-disabled {
      opacity: 0.3;
      pointer-events: auto;
      &:hover {
        cursor: default;
        background-color: transparent;
      }
      &:active {
        background-color: transparent;
      }
    }
  }
`;

export const DownloadIcon = styled(DownloadNoBackground)<{ color?: string }>`
  .fill-selector {
    fill: ${({ color }) => color ?? colors.black};
  }
`;

export const InfoModalContainer = styled.div`
  height: 540px;
  ${mixins.flexColumn};
  justify-content: center;
  background-color: ${colors.black};
  padding: 0 32px;
`;

export const RequestDocumentsIcon = styled(RequestDocuments)`
  width: 85px;
  height: auto;
  .fill-selector {
    fill: ${colors.yellow};
  }
`;

export const DownloadZipFileIcon = styled(DownloadZipFile)`
  width: 85px;
  height: auto;
  .fill-selector {
    fill: ${colors.yellow};
  }
`;

export const Title = styled.div`
  color: ${colors.white};
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
  margin: 25px 0;
`;

export const Subtitle = styled.div`
  color: ${colors.white};
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  padding-bottom: 8px;
`;
