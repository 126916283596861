import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ErrorItem from 'model/State/notifications/ErrorItem';
import InfoItem from 'model/State/notifications/InfoItem';
import { FileCreationItem } from 'model/State/notifications/FileCreationItem';
import FileDownloadSuccessItem from 'model/State/notifications/FileDownloadSuccessItem';
import NotificationsState from 'model/State/NotificationsState';
import RootState from 'model/State/RootState';
import { SuccessItem } from 'model/State/notifications/SuccessItem';
import { InfoModalItem } from 'model/State/notifications/InfoModalItem';
import { ExtensionType } from 'model/FileExport';

const initialState: NotificationsState = {
  error: [],
  info: [],
  fileDownloadSuccess: [],
  fileCreation: [],
  previousId: 0,
  isInfoModalOpen: { isOpen: false, type: null },
  success: [],
};

export const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setGlobalError: (state: NotificationsState, action: PayloadAction<any>) => {
      const errorResponse = action.payload?.response;
      const error: ErrorItem = {
        id: state.previousId + 1,
        error: action.payload,
        ...(errorResponse?.status && {
          statusCode: errorResponse?.status,
        }),
        ...(errorResponse?.data?.detail && {
          title: errorResponse?.data?.detail,
        }),
      };
      state.error = [...state.error, error];
      state.previousId += 1;
    },
    resetGlobalError: (
      state: NotificationsState,
      action: PayloadAction<number>,
    ) => {
      state.error = state.error.filter((error) => error.id !== action.payload);
    },
    setFileDownloadInfo: (
      state: NotificationsState,
      action: PayloadAction<{
        objectId?: string;
        fileName?: string;
      }>,
    ) => {
      const info: InfoItem = {
        id: state.previousId + 1,
        isFileExport: true,
        objectId: action.payload.objectId,
        fileName: action.payload.fileName,
      };
      state.info = [...state.info, info];
      state.previousId += 1;
    },
    resetInfo: (state: NotificationsState, action: PayloadAction<number>) => {
      state.info = state.info.filter((info) => info.id !== action.payload);
    },
    setFileDownloadSuccess: (
      state: NotificationsState,
      action: PayloadAction<{
        fileName?: string;
      }>,
    ) => {
      const success: FileDownloadSuccessItem = {
        id: state.previousId + 1,
        fileName: action.payload.fileName,
      };
      state.fileDownloadSuccess = [...state.fileDownloadSuccess, success];
      state.previousId += 1;
    },
    resetFileDownloadSuccess: (
      state: NotificationsState,
      action: PayloadAction<number>,
    ) => {
      state.fileDownloadSuccess = state.fileDownloadSuccess.filter(
        (success) => success.id !== action.payload,
      );
    },
    setFileCreationToast: (
      state: NotificationsState,
      action: PayloadAction<ExtensionType>,
    ) => {
      state.fileCreation = [
        ...state.fileCreation,
        { id: state.previousId + 1, type: action.payload },
      ];
      state.previousId += 1;
    },
    resetFileCreation: (
      state: NotificationsState,
      action: PayloadAction<number>,
    ) => {
      state.fileCreation = state.fileCreation.filter(
        (item: FileCreationItem) => item.id !== action.payload,
      );
    },
    showInfoModal: (
      state: NotificationsState,
      action: PayloadAction<string>,
    ) => {
      state.isInfoModalOpen = { isOpen: true, type: action.payload };
    },
    hideInfoModal: (state: NotificationsState) => {
      state.isInfoModalOpen = { isOpen: false, type: null };
    },
    setSuccess: (state: NotificationsState, action: PayloadAction<string>) => {
      const success: SuccessItem = {
        id: state.previousId + 1,
        message: action.payload,
      };
      state.success = [...state.success, success];
      state.previousId += 1;
    },
    resetSuccess: (
      state: NotificationsState,
      action: PayloadAction<number>,
    ) => {
      state.success = state.success.filter(
        (success) => success.id !== action.payload,
      );
    },
  },
});

export const {
  setGlobalError,
  resetGlobalError,
  setFileDownloadInfo,
  resetInfo,
  setFileDownloadSuccess,
  resetFileDownloadSuccess,
  setFileCreationToast,
  resetFileCreation,
  showInfoModal,
  hideInfoModal,
  setSuccess,
  resetSuccess,
} = notifications.actions;

export const selectErrors = (state: RootState): ErrorItem[] =>
  state.notifications.error;
export const selectInfoList = (state: RootState): InfoItem[] =>
  state.notifications.info;
export const selectFileDownloadSuccessList = (
  state: RootState,
): FileDownloadSuccessItem[] => state.notifications.fileDownloadSuccess;
export const selectFileCreationNotifications = (
  state: RootState,
): FileCreationItem[] => state.notifications.fileCreation;
export const selectIsInfoModalOpen = (state: RootState): InfoModalItem =>
  state.notifications.isInfoModalOpen;

export const selectSuccessList = (state: RootState): SuccessItem[] =>
  state.notifications.success;

export const notificationsReducer = notifications.reducer;
