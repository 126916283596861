import { Button } from '@mui/material';
import {
  Cross,
  DownloadZipFile,
  RequestDocuments,
  TickCircle,
  Warning,
} from 'assets/icons';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins } = theme;

export const Toast = styled(ToastContainer)<{
  isMobile: boolean;
  backgroundColor: string;
}>`
  &&&.Toastify__toast-container {
    .Toastify__toast--default {
      background-color: ${({ backgroundColor }) => backgroundColor};
      width: 400px;
      border-radius: 0;
      cursor: default;
      ${({ isMobile }) =>
        isMobile &&
        `
        margin: 0 auto;
        width: 310px
      `};
    }
  }

  .Toastify__close-button--default {
    color: ${colors.white};
    opacity: 1;
  }
`;

export const ContentWrapper = styled.div`
  ${mixins.flexBetween};
  align-items: start;
  width: 100%;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const IconContainer = styled.div`
  padding-right: 5px;
  svg {
    width: 32px;
    height: 32px;
  }
`;

export const Title = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
`;

export const Message = styled.div`
  font-family: ${fonts.default};
  font-size: ${fontSizes.default};
  padding-top: 10px;
  padding-bottom: 5px;
`;

export const TextContainer = styled.div<{ $light: boolean }>`
  padding: 5px;
  align-content: center;
  > div {
    color: ${({ $light }) => ($light ? colors.white : colors.black)};
  }
`;

export const WarningIcon = styled(Warning)`
  .fill-selector {
    fill: ${colors.white};
  }
`;

export const TickIcon = styled(TickCircle)`
  .fill-selector {
    fill: ${colors.black};
  }
`;

export const CloseButton = styled(Button)`
  &.MuiButton-root {
    min-width: 0;
    align-items: start;
    border-radius: 0;
    height: 35px;
    padding: 5px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
`;

export const CrossIcon = styled(Cross)<{ $light: boolean }>`
  width: 24px;
  height: 24px;
  .fill-selector {
    fill: ${({ $light }) => ($light ? colors.white : colors.black)};
  }
`;

export const DownloadButton = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    font-family: ${fonts.default};
    font-size: ${fontSizes.default};
    padding: 0;
    &:hover {
      cursor: default;
      text-decoration: underline;
      text-decoration-color: ${colors.white};
    }
    span {
      text-align: left;
      mark {
        color: ${colors.white};
        background-color: transparent;
        text-decoration: underline;
        text-decoration-color: ${colors.white};
        &:hover {
          color: ${colors.black};
          background-color: ${colors.yellow};
          text-decoration: underline;
          text-decoration-color: ${colors.black};
          cursor: pointer;
        }
      }
    }
  }
`;

export const CreatePdfIcon = styled(RequestDocuments)`
  .fill-selector {
    fill: ${colors.darkGrey};
  }
`;

export const DownloadZipIcon = styled(DownloadZipFile)`
  .fill-selector {
    fill: ${colors.darkGrey};
  }
`;

export const FileNameListItem = styled.li<{ light?: boolean }>`
  color: ${({ light }) => (light ? colors.white : colors.black)};
  font-family: ${fonts.default};
  padding-left: 10px;
`;
