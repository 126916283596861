import { useText } from 'hooks';
import {
  Assessment,
  Document,
  Evidence as EvidenceIcon,
  Hazard,
  Note,
  RequestDocuments,
  Warning,
  Permit,
  WorkOrderItem,
} from 'assets/icons';
import { Button } from 'components/ui';
import * as constants from 'utils/constants';

import { MobileHeaderLinksContainer } from '../styled';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsGeneratingZip, selectJobsView } from 'store/selectors';
import { getZipExportId } from 'store/actions/jobs';
import { DownloadSource, DownloadType } from '../../FileExport/constants';

export const MobileHeaderLinks = ({ jobId, isStreamline, permitsEnabled }) => {
  const getText = useText();
  const view = useSelector(selectJobsView);
  const dispatch = useDispatch();
  const isGeneratingZip = useSelector(selectIsGeneratingZip);

  const startZipCreation = () => {
    const eventProperties = {
      jobId,
      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      download_source: DownloadSource.JOB_OVERVIEW,
      download_type: DownloadType.JOB,
    };
    dispatch(getZipExportId(jobId, eventProperties));
  };

  return (
    <MobileHeaderLinksContainer>
      <Button.SecondaryLink
        event={constants.EVENT_VIEWED_HAZARDS_CONTROLS_LIST}
        eventProperties={{
          jobId,
          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        }}
        text={getText('tabs_hazards_and_controls')}
        to={`/jobs/${jobId}/hazards-controls`}
        IconComponent={Hazard}
        extraStyles={{ margin: '0', marginBottom: '15px' }}
      />

      <Button.SecondaryLink
        event={constants.EVENT_VIEWED_RISK_ASSESSMENTS_LIST}
        eventProperties={{
          jobId,
          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        }}
        text={getText('tabs_risk_assessments')}
        to={`/jobs/${jobId}/assessments`}
        IconComponent={Assessment}
        extraStyles={{ margin: '0', marginBottom: '15px' }}
      />

      <Button.SecondaryLink
        event={constants.EVENT_VIEWED_JOB_EVIDENCE_LIST}
        eventProperties={{
          jobId,
          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        }}
        text={getText('tabs_evidence')}
        to={`/jobs/${jobId}/evidence`}
        IconComponent={EvidenceIcon}
        extraStyles={{ margin: '0', marginBottom: '15px' }}
      />

      <Button.SecondaryLink
        event={constants.EVENT_VIEWED_JOB_BLOCKER_LIST}
        eventProperties={{
          jobId,
          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        }}
        text={getText('tabs_job_blockers')}
        to={`/jobs/${jobId}/blockers`}
        IconComponent={Warning}
        disabled={isStreamline}
        extraStyles={{ margin: '0', marginBottom: '15px' }}
      />

      <Button.SecondaryLink
        event={constants.EVENT_VIEWED_DOCUMENTS_LIST}
        eventProperties={{
          jobId,
          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        }}
        text={getText('tabs_documents')}
        to={`/jobs/${jobId}/documents`}
        IconComponent={Document}
        disabled={isStreamline}
        extraStyles={{ margin: '0', marginBottom: '15px' }}
      />

      {!isStreamline && (
        <>
          <Button.SecondaryLink
            event={constants.EVENT_VIEWED_WORK_COMPLETED_LIST}
            eventProperties={{
              jobId,
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            text={getText('tabs_work_completed')}
            to={`/jobs/${jobId}/work-completed`}
            IconComponent={WorkOrderItem}
            extraStyles={{ margin: '0', marginBottom: '15px' }}
          />

          <Button.SecondaryLink
            event={constants.EVENT_VIEWED_LOG_LIST}
            eventProperties={{
              jobId,
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            text={getText('tabs_logs')}
            to={`/jobs/${jobId}/logs`}
            IconComponent={Note}
            extraStyles={{ margin: '0', marginBottom: '15px' }}
          />

          <Button.Secondary
            event={constants.EVENT_JOB_REQUEST_FILES}
            eventProperties={{
              jobId,
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            text={getText('tabs_request_files')}
            onClick={startZipCreation}
            disabled={isGeneratingZip}
            IconComponent={RequestDocuments}
            extraStyles={{ margin: '0', marginBottom: '15px' }}
          />
        </>
      )}
      {permitsEnabled && (
        <Button.SecondaryLink
          event={constants.EVENT_VIEWED_PERMITS_LIST}
          eventProperties={{
            jobId,
            [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
          }}
          text={getText('tabs_permits')}
          to={`/jobs/${jobId}/permits`}
          IconComponent={Permit}
          extraStyles={{ margin: '0', marginBottom: '15px' }}
        />
      )}
    </MobileHeaderLinksContainer>
  );
};
