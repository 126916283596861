import { Document } from 'api';
import { DownloadSource } from 'model/enum/DownloadSource';
import { put, takeLatest } from 'redux-saga/effects';
import { DOWNLOAD_DOCUMENT } from 'store/actions/actionTypes';
import {
  setGlobalError,
  setFileDownloadSuccess,
} from 'store/slices/notifications';
import * as Logger from 'utils/logger';
import * as Analytics from 'utils/analytics';
import {
  EMAIL_SOURCE,
  EVENT_DOWNLOAD_TOAST_POPUP,
} from 'components/views/Protected/Jobs/FileExport/constants';
import {
  getFileExportDataFromLocalStorage,
  removeFileExportDataFromLocalStorage,
} from 'helpers/files';

function* downloadDocument({ fileId, source, fileName }) {
  try {
    const documentFileName = yield Document.downloadDocument(fileId, fileName);
    if (source === DownloadSource.EMAIL) {
      yield put(
        setFileDownloadSuccess({
          fileName: documentFileName,
        }),
      );

      Analytics.trackEvent(EVENT_DOWNLOAD_TOAST_POPUP, {
        ...getFileExportDataFromLocalStorage(fileId),
        page_source: EMAIL_SOURCE,
      });
      removeFileExportDataFromLocalStorage(fileId);
    }
  } catch (err) {
    Logger.error(err);
    yield put(setGlobalError(err));
  }
}

export default function* documentsWatcher() {
  yield takeLatest(DOWNLOAD_DOCUMENT, downloadDocument);
}
