import { Button, Input, Modal } from 'components/ui';
import {
  DownloadZipFileIcon,
  InfoModalContainer,
  RequestDocumentsIcon,
  Subtitle,
  Title,
} from './styled';
import { useLocalStorage, useText } from 'hooks';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  hideInfoModal,
  selectIsInfoModalOpen,
} from 'store/slices/notifications';
import theme from 'theme';
import { selectCurrentUserId } from 'store/selectors';
import { ExtensionType } from 'model/FileExport';

export const ExportInfoModal = (): JSX.Element | null => {
  const getText = useText();
  const dispatch = useDispatch();
  const currentUserId = useSelector(selectCurrentUserId);
  const { isOpen, type } = useSelector(selectIsInfoModalOpen);
  const [doNotShowByUser, setDoNotShowByUser] = useLocalStorage<{
    [key: number]: boolean;
  }>(`${type}InfoModalDismissed`, {});
  const [isDoNotShowChecked, setDoNotShowChecked] = useState<boolean>(false);

  const title =
    type === ExtensionType.PDF
      ? 'pdf_export_info_modal_title'
      : 'create_zip_file_info_modal_title';

  const closeModal = () => {
    dispatch(hideInfoModal());
  };

  const handleSubmit = () => {
    if (isDoNotShowChecked) {
      setDoNotShowByUser({ ...doNotShowByUser, [currentUserId]: true });
    }
    dispatch(hideInfoModal());
  };

  if (!isOpen || doNotShowByUser?.[currentUserId]) {
    return null;
  }

  return (
    <Modal.Base
      isOpen={isOpen}
      onClose={closeModal}
      width='40vw'
      showTitleBar
      isCentered
    >
      <InfoModalContainer>
        {type === ExtensionType.PDF ? (
          <RequestDocumentsIcon />
        ) : (
          <DownloadZipFileIcon />
        )}

        <Title>{getText(title)}</Title>
        <Subtitle>{getText('info_modal_subtitle_line_1')}</Subtitle>
        <Subtitle>{getText('info_modal_subtitle_line_2')}</Subtitle>
        <Input.Checkbox
          checked={isDoNotShowChecked}
          onChange={() => setDoNotShowChecked(!isDoNotShowChecked)}
          label={getText('info_modal_dismiss')}
          labelPlacement='end'
          labelColor={theme.colors.white}
          hasBlackBackground
          extraStyles={{ alignItems: 'start', marginTop: '20px' }}
        />
      </InfoModalContainer>
      <Button.Primary
        text={getText('ok').toUpperCase()}
        onClick={handleSubmit}
        extraStyles={{
          position: 'sticky',
          bottom: '0',
          marginTop: 'auto',
          flex: '0 0 55px',
        }}
      />
    </Modal.Base>
  );
};
